import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../components/common/BreadCrumbs'
import FormButton from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import { getReq } from '../../apis/api'
import { toast } from 'react-toastify'
import Loader from '../../components/loader/Loading'
import AllOrdersTable from '../../components/DataTable/AllOrdersTable'

const OrderManagement = () => { 
    const navigate=useNavigate();  
    const [isLoading, setIsLoading] = useState(false); 
    const [orders,setOrders]=useState([]);

    const fetchOrderData = async () => {
      try {
        setIsLoading(true);
        const response = await getReq("/fetchOrders");
  
        if (response.status === 200) {
          setOrders(response.data);
        }
      } catch (error) {
        console.log("Error", error);
        toast.error("Error in Fetching Product Categories");
      } finally {
        setIsLoading(false);
      }
    };
  
    useEffect(() => {
      fetchOrderData();
    }, []); 


    const columns = [
      {
        name: "#",
        selector: (row, index) => index + 1,
        sortable: false,
        width: "100px",
        center: true,
      },
      {
        name: "ID",
        selector: (row) => row.order_id,
        sortable: true,
        width: "100px",
      }, 
      {
        name: "Invoice No",
        selector: (row) => row.invoice_number || "N/A",
        sortable: true,
        wrap: true,
      },
     
      {
        name: "Date",
        selector: (row) => row.order_date,
        sortable: true,
      },
  
      {
        name: "Name",
        selector: (row) => `${row.billing_address.name}` || "N/A",
        sortable: true,
      },  
      {
        name: "City",
        selector: (row) => row.billing_address.city || "N/A",
        sortable: true,
        wrap: true,
      },  
       {
        name: "Address",
        selector: (row) => row.billing_address.address_line_1 || "N/A",
        sortable: true,
        wrap: true,
      },   
      {
        name: "Mobile",
        selector: (row) => row.billing_address.phone || "N/A",
        sortable: true,
        wrap: true,
      },  
      {
        name: "Products",
        selector: (row) => Array.isArray(row.products) 
          ? row.products.map(product => product.product_name).join(", ") 
          : "N/A",
        sortable: true,
      } ,
    
      {
        name: "Amount",
        selector: (row) => row.total_amount || "N/A",
        sortable: true,
        wrap: true,
      },  
     
 

      {
        name: "Delivery",
        selector: (row) => row.estimated_delivery_date || "N/A",
        sortable: true,
        wrap: true,
      },  
      {
        name: "Status",
        selector: (row) => row.status || "N/A",
        cell: (row) => (
          <div
            className={`px-4 py-1 rounded-[0.42vw]  ${
              row.status === "Enable"
                ? "bg-[#d8fff8] text-[#01bd9b]"
                : row.status === "Disable"
                ? "bg-[#faebe9] text-[#D0412E]"
                : "bg-gray-200 text-gray-600"
            }`}
          >
            {row.status || "N/A"}
          </div>
        ),
        sortable: true,
        wrap: true,
      }, 
    ];



  return (
    <div>
       <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs title={"Home  /  Sales-Services / Orders"} />
        <FormButton title="Create" func={()=>navigate("/sales-service/create-order")} />
      </div>  

      <main>
        {isLoading ? (
          <Loader />
        ) : (
          <AllOrdersTable columns={columns} data={orders}   onItemAdded={fetchOrderData}/>
        )}
      </main>

    </div>
  )
}

export default OrderManagement
