import { FiArrowRight, FiChevronDown } from "react-icons/fi";
import {
  FaUserFriends,
  FaChartPie,
  FaShoppingCart,
  FaHammer,
  FaSitemap,
  FaRupeeSign,
  FaFileInvoice,
  FaCalendarAlt,
} from "react-icons/fa";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const sidebarOptions = [
  {
    name: "Dashboard",
    icon: <FaChartPie />,
    path: "/dashboard",
    subOptions: [{ name: "Reporting", path: "/dashboard/reporting" }],
  },
  {
    name: "Users",
    icon: <FaUserFriends />,
    path: "/users",
  },
  {
    name: "Item/Subitem",
    icon: <FaSitemap />,
    path: "/items",
    subOptions: [
      { name: "Item Categories", path: "/items/categories" },
      { name: "Items", path: "/items" },
      { name: "Subitems", path: "/items/subitems" },
      { name: "Assets", path: "/items/assets" },
    ],
  },
  {
    name: "Purchase",
    icon: <FaFileInvoice />,
    path: "/purchases",
    subOptions: [
      { name: "Purchase Orders", path: "/purchases/purchase-order" },
    ],
  },
  {
    name: "Payments",
    icon: <FaRupeeSign />,
    path: "/payment",
    subOptions: [{ name: "Other Income", path: "/payments/income-management" }],
  },
  {
    name: "Manufacturing",
    icon: <FaHammer />,
    path: "/manufacturing",
    subOptions: [
      { name: "Product Categories", path: "/manufacturing/add-product" },
      {
        name: "Products Management",
        path: "/manufacturing/products-management",
      },
    ],
  },
  {
    name: "Sales/Service",
    icon: <FaShoppingCart />,
    path: "/sales-service",
    subOptions: [ 
      { name: "Manage Orders", path: "/sales-service/manage-orders" },
      { name: "Dealer Order Request", path: "/sales-service/dealer-order" },
      { name: "Warranty Management", path: "/sales-service/warraty-Manage" },
      { name: "Product Service", path: "/sales-service/prod-service" },
    ],
  },
  {
    name: "Attendance",
    icon: <FaCalendarAlt />,
    path: "/attendance",
    subOptions: [
      { name: "Employee Wise Report", path: "/attendance/employee-report" },
    ],
  },
];

const HorizontalSidebar = () => {
  const [hoveredOption, setHoveredOption] = useState(null);
  const [activeOption, setActiveOption] = useState(0);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const navigate = useNavigate();

  const handleMouseEnter = (option) => {
    // Clear any existing timeout when user hovers over the parent option
    if (hoverTimeout) clearTimeout(hoverTimeout);
    setHoveredOption(option);
  };

  const handleMouseLeave = () => {
    // Add a small delay before closing the submenu
    const timeout = setTimeout(() => {
      setHoveredOption(null);
    }, 300);
    setHoverTimeout(timeout);
  };

  const handleNavigation = (path, option) => {
    navigate(path);
    setActiveOption(option);
    setHoveredOption(null);
  };

  return (
    <div className="fixed w-full left-[9.5vw] right-0  flex flex-nowrap gap-[0.9vw] justify-start items-center">
      {sidebarOptions.map((option, index) => {
        const isActive = activeOption === option;
        const hasSubOptions = option.subOptions && option.subOptions.length > 0;

        return (
          <div
            key={index}
            className={`relative cursor-pointer flex items-center gap-2 ${
              isActive ? " text-blue-500" : ""
            }`}
            onMouseEnter={() => handleMouseEnter(option)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleNavigation(option.path, option)}
          >
            <div
              className={`flex items-center gap-x-[0.2vw] rounded-[8px] bg-transparent p-2 ${
                hoveredOption === option || isActive
                  ? "shadow-[0_0_0_1px_#dee1ed] text-blue-500"
                  : ""
              } duration-300`}
              style={{
                backgroundColor:
                  hoveredOption === option || isActive
                    ? "#0074ff1a"
                    : "transparent",
              }}
            >
              <span
                className={`text-[1vw] duration-300 ${
                  hoveredOption === option || isActive
                    ? "text-blue-500"
                    : "text-[#96a0af]"
                }`}
              >
                {option.icon}
              </span>
              <div
                className={`text-[1.1vw] font-poppins font-medium flex items-center ${
                  hoveredOption === option || isActive
                    ? "text-blue-500"
                    : "text-[#343E53]"
                }`}
              >
                {option.name}
                {hasSubOptions && (
                  <span
                    className={`text-[1vw] transition-colors duration-300 ${
                      hoveredOption === option || isActive
                        ? "text-blue-500"
                        : "text-[#96a0af]"
                    }`}
                  >
                    <FiChevronDown />
                  </span>
                )}
              </div>
            </div>

            {hoveredOption === option && hasSubOptions && (
              <div
                className="absolute text-[1.1vw] w-[15vw] font-poppins shadow-md z-30 top-[2.8vw] left-0 rounded-[8px] p-2 bg-white duration-300 ease-in-out"
                onMouseEnter={() => handleMouseEnter(option)} // Ensure submenu stays open
                onMouseLeave={handleMouseLeave} // Close only when both parent and submenu are not hovered
                style={{
                  opacity: hoveredOption === option ? 1 : 0,
                  maxHeight: hoveredOption === option ? "300px" : "0",
                  transition: "opacity 0.3s ease, max-height 0.3s ease",
                  overflow: "hidden",
                }}
              >
                {option.subOptions.map((subOption, subIndex) => (
                  <div
                    key={subIndex}
                    className="flex items-center justify-between px-2 py-[8px] text-[#343E53] bg-white cursor-pointer hover:text-blue-500 group"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleNavigation(subOption.path, option);
                    }}
                  >
                    <span className="bg-white group-hover:text-blue-500">
                      {subOption.name}
                    </span>
                    <span className="md:text-[1.5vw] text-[#343E53] opacity-0 group-hover:bg-white group-hover:opacity-100 group-hover:text-blue-500 transition-opacity duration-300">
                      <FiArrowRight className="bg-white" />
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default HorizontalSidebar;
