import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { TextField, Grid, Typography, Divider, IconButton, Button, MenuItem, InputAdornment } from '@mui/material';
import { Add as AddIcon, Assignment, Delete as DeleteIcon } from '@mui/icons-material';
import FormButton from '../../components/Button';
import { useParams } from 'react-router-dom';
import { getReq, postReq } from '../../apis/api';

    // Inside the PurchaseOrderForm component, watch for changes in logisticsAmount, carriageAmount, or item quantities
    const PurchaseOrderForm = () => {
      const { control, handleSubmit, setValue, watch, reset } = useForm({
          defaultValues: {
              logisticsAmount: 0,
              carriageAmount: 0,
              items: []
          }
      });
      
      const { fields, append, remove } = useFieldArray({
        control,
        name: 'items',
      });
      
      const logisticsAmount = watch('logisticsAmount');
      const carriageAmount = watch('carriageAmount');
    
      const calculateLPlusC = (logisticsAmount, carriageAmount, items, setValue) => {
        const totalQuantity = items.reduce((sum, item) => sum + (Number(item.quantity) || 0), 0); // Safely parse numbers
        if (totalQuantity > 0) {
          items.forEach((item, index) => {
            const itemQuantity = Number(item.quantity) || 0;
            const lPlusC = ((logisticsAmount + carriageAmount) / totalQuantity) * itemQuantity;
            setValue(`items[${index}].lPlusC`, lPlusC.toFixed(2)); // Update lPlusC field with calculated value
          });
        } else {
          // Reset lPlusC to 0 if totalQuantity is 0 to avoid NaN
          items.forEach((_, index) => {
            setValue(`items[${index}].lPlusC`, "0.00");
          });
        }
      };
      // Function to calculate SGEW Price for each item
const calculateSGEWPrice = (items, setValue) => {
  items.forEach((item, index) => {
    const supplierAmount = Number(item.supplierAmount) || 0;
    const cgstPercentage = Number(item.cgstSgst) || 0;
    const lPlusC = Number(item.lPlusC) || 0;

    // Calculate SGEW Price
    const cgstAmount = (supplierAmount * cgstPercentage) / 100;
    const sgewPrice = supplierAmount + cgstAmount + lPlusC;

    // Update the SGEW Price for the item
    setValue(`items[${index}].sgewPrice`, sgewPrice.toFixed(2));
  });
};
      useEffect(() => {

        if (logisticsAmount !== undefined && carriageAmount !== undefined && fields.length > 0) {
          calculateLPlusC(Number(logisticsAmount), Number(carriageAmount), fields, setValue);
        }
        if (fields.length > 0) {
          calculateSGEWPrice(fields, setValue);
        }
      }, [logisticsAmount, carriageAmount, fields, setValue]);
    

          // Watching the total amount (optional if dynamically updated in UI)
          const watchTotalAmount = watch('supplierAmount');
      const calculateTotalInvoiceAmount = (items) => {
        // Calculate the total of all `sgewPrice` values
        const total = items.reduce(
          (sum, item) => sum + (Number(item.sgewPrice) || 0),
          0
        );
        setValue('totalInvoiceAmount', total.toFixed(2)); // Update the form field
        setValue('roundedOffAmount', total.toFixed(2));
      };
      
      useEffect(() => {
        calculateTotalInvoiceAmount(fields); // Recalculate when fields change
      }, [fields,watchTotalAmount]); // Dependencies include fields
      
  
     

  const { id } = useParams();
  const [itemsList, setItemsList] = useState([]);
  
  const [suppliers,setSuppliers] = useState([]); 
  

  const [isEditing, setIsEditing] = useState(false);



  useEffect(() => {
    // Fetch purchase data if in view/edit mode
    if (id) {
        getReq(`fetchPurchase/${id}`)
            .then(response => {
                const data = response.data; // Access the 'data' object directly
                // Map API data to form structure
                console.log(data.purchase_order_number,"ppppp");
                const mappedData = {
                    purchaseOrderNumber: data.purchase_order_number,
                    supplierID: data.supplier_id,
                    orderDate: data.order_date,
                    expectedDeliveryDate: data.expected_delivery_date,
                    invoiceNumber: data.invoice_number,
                    invoiceDate: data.invoice_date,
                    ewayNumber: data.eway_number,
                    invoicePDF: data.invoice_pdf || '', // Check if invoice_pdf exists
                    logisticsAmount: data.logistics_amount,
                    carriageAmount: data.carriage_amount,
                    status: data.status,
                    comment: data.comment,
                    totalInvoiceAmount: 0,
                    roundedOffAmount:0,
                    items: data.items_included.map(item => ({
                        itemName: item.item_id, // Adjust to display actual item name if needed
                        hsn: item.item_hsn,
                        quantity: item.iem_qty, // Note: corrected from `item_qty`
                        length_mm: item.length_mm,
                        width_mm: item.width_mm,
                        thickness_mm: item.thickness_mm,
                        weight_kg: item.weight_kg,
                        supplierAmount: item.supplier_amount,
                        carriageAmount: data.carriage_amount, // from main data
                        logisticsAmount: data.logistics_amount, // from main data
                        lPlusC : 0,
                        sgewPrice: '0',
                        cgstSgst:18
                        
                    })),
                };
                reset(mappedData);
            })
            .catch(error => console.error('Error fetching data:', error));
    }
}, [id, reset]);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await getReq("/fetchItems");
        if (response.status === 200) {
          const fetchedItems = response.data.map((item) => ({
            id: item.item_id,
            name: item.item_name,
            category: item.item_category,
            hsn: item.hsn,
            tag: item.item_tag,
            unit_price: item.unit_price,
            length_mm: item.default_length_mm,
            width_mm: item.default_width_mm,
            thickness_mm: item.default_thickness_mm,
            weight_kg: item.default_weight_kg,
          }));

          setItemsList(fetchedItems);
        } else {
          console.error("Error fetching items:", response.message);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };
    fetchItems();
    const fetchSuppliers = async () => {
      try {
        const response = await getReq("/fetchSupplier"); // Fetch data from API
        if (response.status === 200) {
          setSuppliers(response.data); // Set the fetched data to state
        } else {
          console.error("Error fetching purchase orders:", response.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchSuppliers();
  }, []);

  const ResetData = () => (
    setIsEditing(true),
    {
    purchaseOrderNumber: '',
    supplierID: '',
    orderDate: '',
    expectedDeliveryDate: '',
    invoiceNumber: '',
    invoiceDate: '',
    ewayNumber: '',
    invoicePDF: '',
    logisticsAmount: '',
    carriageAmount: '',
    status: '',
    comment: '',
    items: [
      {
        itemName: '',
        hsn: '',
        quantity: '',
        length_mm: '',
        width_mm: '',
        thickness_mm: '',
        weight_kg: '',
        supplierAmount: '',
        sgewPrice: '',
        totalInvoiceAmount: '',
        roundOfAmount: '',
      }
    ],
  });
  
  

  const handleEdit = () => {
    setIsEditing(true);
    
    console.log(isEditing,"asdfghjk");
  };
  const handleCancel = () => {
    setIsEditing(false);
  };
  const handleCreateNewInvoice = () => {
    setIsEditing(true);
    reset(); // Clears all fields for a new invoice
  };

  const onSubmit = (data) => {
        const { logisticsAmount, carriageAmount, items } = data;
        data.items = calculateLPlusC(Number(logisticsAmount), Number(carriageAmount), items);
    console.log('Form submitted:', data);
    setIsEditing(false);
    postReq('/')
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', margin: '0 auto', padding: '20px', background: '#f9f9f9', borderRadius: '8px' }}>
      <Typography variant="h5" gutterBottom>
        {isEditing ? "Edit Purchase Order / Create New Purchase Invoice" : "View Purchase Order / Purchase Invoice"}
      </Typography>
      <Divider />

      <Grid container spacing={2} className="pt-6">
        {/* Basic Details */}
        <Grid item xs={12} sm={3}>
          <Controller name="purchaseOrderNumber" control={control} render={({ field }) => (
            <TextField {...field} label="Purchase Order Number" fullWidth InputLabelProps={{ shrink: true }} disabled={!isEditing} />
          )} />
        </Grid>
      
       
        <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="supplierID"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    label="Supplier ID"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem value="" disabled>Select a supplier</MenuItem>
                    {suppliers.map(supplier => (
                      <MenuItem key={supplier.id} value={supplier.id}>{supplier.user_name}</MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

        <Grid item xs={12} sm={3}>
          <Controller name="orderDate" control={control} render={({ field }) => (
            <TextField {...field} label="Order Date" type="date" fullWidth InputLabelProps={{ shrink: true }} disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="expectedDeliveryDate" control={control} render={({ field }) => (
            <TextField {...field} label="Expected Delivery Date" type="date" fullWidth InputLabelProps={{ shrink: true }} disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="invoiceNumber" control={control} render={({ field }) => (
            <TextField {...field} label="Invoice Number" InputLabelProps={{ shrink: true }} fullWidth disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="invoiceDate" control={control} render={({ field }) => (
            <TextField {...field} label="Invoice Date" type="date" fullWidth InputLabelProps={{ shrink: true }} disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="ewayNumber" control={control} render={({ field }) => (
            <TextField {...field} label="Eway Number" fullWidth InputLabelProps={{ shrink: true }} disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="invoicePDF" control={control} render={({ field }) => (
            <TextField {...field} type="file" label="Invoice PDF(s)" fullWidth InputLabelProps={{ shrink: true }} disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="logisticsAmount" control={control} render={({ field }) => (
            <TextField {...field} label="Logistics Amount" fullWidth InputLabelProps={{ shrink: true }} disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="carriageAmount" control={control} render={({ field }) => (
            <TextField {...field} label="Carriage Amount" fullWidth InputLabelProps={{ shrink: true }} disabled={!isEditing} />
          )} />
        </Grid>
       
        <Grid item xs={12} sm={6} md={4}>
      <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            select
            fullWidth
            label="Status"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            disabled={!isEditing}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Assignment />
                </InputAdornment>
              ),
            }}
     
          >
            <MenuItem value="1">Received</MenuItem>
            <MenuItem value="0">Due</MenuItem>
          </TextField>
        )}
      />
    </Grid>

        <Grid item xs={12}>
          <Controller name="comment" control={control} render={({ field }) => (
            <TextField {...field} label="Comment" multiline rows={2} InputLabelProps={{ shrink: true }} fullWidth disabled={!isEditing} />
          )} />
        </Grid>

        {/* Items Section */}
        <Grid item xs={12}>
          <Typography variant="h6">Items Included</Typography>
          <Divider />
        </Grid>

        {fields.map((item, index) => (
  <Grid container spacing={2} key={item.id} alignItems="center" style={{ marginBottom: '16px', marginTop: '10px', marginLeft: '4px' }}>
  
    <Grid item xs={12} sm={6} md={2}>
                      <Controller
                       name={`items[${index}].itemName`}
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} select fullWidth label="Item Name" variant="outlined" InputLabelProps={{ shrink: true }} disabled={!isEditing}>
                            {itemsList?.map(itm => (
                              <MenuItem key={itm.id} value={itm.id}>{itm.name}</MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
  
    {/* <Grid item xs={12} sm={1} md={1}>
      <Controller name={`items[${index}].quantity`} control={control} render={({ field }) => (
        <TextField {...field} label="Quantity" fullWidth disabled={!isEditing} />
      )}/>
    </Grid> */}
    <Grid item xs={12} sm={2}>
  <Controller
    name={`items[${index}].quantity`}
    control={control}
    render={({ field }) => (
      <TextField
        {...field}
        label="Quantity"
        type="number"
        fullWidth
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        disabled={!isEditing}
        onChange={(e) => {
          field.onChange(e); // Update quantity
          calculateLPlusC(
            Number(logisticsAmount),
            Number(carriageAmount),
            fields.map((f, idx) => ({
              ...f,
              quantity: idx === index ? e.target.value : f.quantity,
            })),
            setValue
          );
        }}
      />
    )}
  />
</Grid>

<Grid item xs={3}  md={1.2}>
      <Controller
        name={`items[${index}].supplierAmount`}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Supplier Amount"
            type="number"
            InputLabelProps={{ shrink: true }}
            disabled={!isEditing}
            onChange={(e) => {
              field.onChange(e); // Update value in form state
              calculateSGEWPrice(watch('items'), setValue); // Recalculate SGEW
            }}
          />
        )}
      />
    </Grid>
    <Grid item xs={3} md={1.2}>
      <Controller
        name={`items[${index}].cgstSgst`}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="CGST/SGST (%)"
            type="number"
            InputLabelProps={{ shrink: true }}
            disabled={!isEditing}
            onChange={(e) => {
              field.onChange(e); // Update value in form state
              calculateSGEWPrice(watch('items'), setValue); // Recalculate SGEW
            }}
          />
        )}
      />
    </Grid>
    
    <Grid item xs={12} sm={2} md={1.2}>
      <Controller name={`items[${index}].lPlusC`} control={control} render={({ field }) => (
        <TextField {...field} label="L+C" InputLabelProps={{ shrink: true }} fullWidth disabled={!isEditing} />
      )}/>
    </Grid>
    <Grid item xs={12} sm={2} md={1.2}>
      <Controller name={`items[${index}].sgewPrice`} control={control} render={({ field }) => (
        <TextField {...field} label="SGEW Price" InputLabelProps={{ shrink: true }} fullWidth disabled={!isEditing} />
      )}/>
    </Grid>
    <Grid item xs={12} sm={2} md={1.2}>
      <Controller name={`items[${index}].weight`} control={control} render={({ field }) => (
        <TextField {...field} label="Weight (kg)" InputLabelProps={{ shrink: true }} fullWidth disabled={!isEditing} />
      )}/>
    </Grid>
    
    {isEditing && (
      <>
        {/* Delete Button */}
        <Grid item xs={0.5}>
          <IconButton onClick={() => remove(index)} color="error">
            <DeleteIcon />
          </IconButton>
        </Grid>

        {/* Add Item Button - Only on last item */}
        {index === fields.length - 1 && (
          <Grid item xs={0.5}>
            <IconButton onClick={() => append({})} color="primary">
              <AddIcon />
            </IconButton>
          </Grid>
        )}
      </>
    )}
  </Grid>
))}

        {/* Totals Section */}
        <Grid item xs={12}>
          <Typography variant="h6">Totals</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={3}>
      <Controller
        name="totalInvoiceAmount"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Total Invoice Amount"
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled={!isEditing}
          />
        )}
      />
    </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="roundedOffAmount" control={control} render={({ field }) => (
            <TextField {...field} label="Rounded Off Amount" InputLabelProps={{ shrink: true }} fullWidth disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="totalWithGST" control={control} render={({ field }) => (
            <TextField {...field} label="Total with GST" fullWidth InputLabelProps={{ shrink: true }} disabled={!isEditing} />
          )} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller name="grandTotal" control={control} render={({ field }) => (
            <TextField {...field} label="Grand Total" fullWidth InputLabelProps={{ shrink: true }} disabled={!isEditing} />
          )} />
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="end" style={{ marginTop: '20px' }}>
        {isEditing ? (
          <div className='flex gap-1'>
 <FormButton type="submit" title={"Save Changes"}  color="primary" variant="contained"/>
 {/* <FormButton  title={"cancle"}  color="primary" variant="contained"/> */}
 <FormButton
                 func={handleCancel}
                  title="cancel"
                  customcls=" bg-[#0074ff] text-[#ffff] border border-[#d0412e33]"
                />
          </div>
        ) : (
       <div className='flex justify-start gap-1'>
          <button onClick={handleEdit} >
          <FormButton
 
        title="Edit"
         customcls="bg-[#0074ff] text-[#ffff] border border-[#d0412e33]"
/>
          </button>
          <button onClick={ResetData} >
          <FormButton
 
        title="Create New Invoice"
         customcls="bg-[#0074ff] text-[#ffff] border border-[#d0412e33]"
/>
          </button>
       </div>
        )}
      </Grid>
    </form>
  );
};

export default PurchaseOrderForm;    

