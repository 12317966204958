import React, { useState } from 'react'
import BreadCrumbs from '../../components/common/BreadCrumbs'
import FormButton from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import AddCategory from '../../components/Modal/productmodal/AddCategory'
import AddDealer from '../../components/Modal/ordermodal/AddDealer'

const DealerManagement = () => {  
    const [openModal, setOpenModal] = useState(false);

    const handleButtonClick = () => {
    setOpenModal(!openModal);
  }; 

  return (
    <div> 
     <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs title={"Home  /  Sales-Services / Dealer Orders"} />
        <FormButton title="Create" func={handleButtonClick} />
      </div>   

      {openModal && (
        <AddDealer
          open={openModal}
          handleClose={() => setOpenModal(false)}
          title={"Add Dealer Order"}
        />
      )}
      
    </div>
  )
}

export default DealerManagement
