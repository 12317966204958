import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  Grid,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from "@mui/material";
import { toast } from "react-toastify";
import { getReq } from "../../../apis/api";

const Invoice = ({ open, handleClose, ItemId }) => {
  const [invoiceData, setInvoiceData] = useState(null);
  const [settingData, setSettingData] = useState(null); // Separate state for settingData
  const [loading, setLoading] = useState(true);

  // Function to fetch invoice and setting data
  const fetchInvoice = async () => {
    try {
      setLoading(true);
      const res = await getReq(`/orderInvoice/${ItemId}`);
      console.log("Response received:", res);

      // Extract the invoice and settingdata from the response
      const invoice = res?.data?.[0]; // Accessing first item in the array
      setInvoiceData(invoice);

      const settingData = res?.settingdata || []; // Adjusted to look at the top level for settingdata
      setSettingData(settingData);

      if (settingData.length > 0) {
        console.log("Setting Data:", settingData);
      } else {
        console.log("No setting data found");
      }
    } catch (error) {
      console.error("Error fetching invoice data:", error);
      toast.error("Error in fetching invoice data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open && ItemId) {
      console.log(`Invoice modal is open, ItemId: ${ItemId}`);
      fetchInvoice();
    }
  }, [open, ItemId]);

  if (!open) return null;

  console.log("Rendering Invoice Dialog", invoiceData);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      sx={{ boxShadow: "none" }}
    >
      {/* Header */}
      <DialogTitle
        sx={{ textAlign: "center", fontWeight: "bold", fontSize: "1.5rem" }}
      >
        Tax Invoice
      </DialogTitle>

      <Divider  />

      <DialogContent sx={{ padding: "24px" }}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="300px"
          >
            <CircularProgress />
          </Box>
        ) : invoiceData ? (
          <Box>
            <Grid container spacing={3} >
              <Grid item xs={4}>
                <Typography variant="body2" fontWeight="bold">
                  Invoice Number:
                </Typography>
                <Typography variant="body2">
                  {invoiceData.invoice_number}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" fontWeight="bold">
                  E-way Bill Number:
                </Typography>
                <Typography variant="body2">
                  {invoiceData.eway_bill_number}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" fontWeight="bold">
                  Order Date:
                </Typography>
                <Typography variant="body2">
                  {new Date(invoiceData.order_date).toLocaleDateString()}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 1 }} />
            {/* Seller and Buyer Information */}
            <Grid container spacing={2}>
              {/* Render SettingData */}
              {settingData && settingData.length > 0 ? (
                settingData.map((company) => (
                  <Grid item xs={12} sm={4} key={company.id}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      mb={2}
                      sx={{
                        borderBottom: "2px solid #e0e0e0",
                        paddingBottom: "8px",
                      }}
                    >
                      Company Details
                    </Typography>
                    <Typography variant="body2" fontWeight="bold">
                      {company.company_name}
                    </Typography>
                    <Typography variant="body2">
                      {company.company_address1}, {company.company_address2}
                    </Typography>
                    <Typography variant="body2">
                      {company.company_phone1}
                    </Typography>
                    <Typography variant="body2">
                      GSTIN/UIN: {company.gstin_number}
                    </Typography>
                    <Typography variant="body2">
                      State: {company.state}
                    </Typography>
                    <Typography variant="body2">
                      Email: {company.company_email}
                    </Typography>
                  </Grid>
                ))
              ) : (
                <Typography>No company details available.</Typography>
              )}

              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  mb={2}
                  sx={{
                    borderBottom: "2px solid #e0e0e0",
                    paddingBottom: "8px",
                  }}
                >
                  Billing
                </Typography>
                <Typography variant="body2">
                  {invoiceData.billing_address?.name}
                </Typography>
                <Typography variant="body2">
                  {invoiceData.billing_address?.address_line_1},{" "}
                  {invoiceData.billing_address?.address_line_2}
                </Typography>
                <Typography variant="body2">
                  GST Number: {invoiceData.billing_address?.gst_number}
                </Typography>
                <Typography variant="body2">
                  State: {invoiceData.billing_address?.state}
                </Typography>
                <Typography variant="body2">
                  Contact: {invoiceData.billing_address?.phone}
                </Typography>
                <Typography variant="body2">
                  Vehicle Number:{" "}
                  {invoiceData.billing_address?.motor_vehicle_number}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  mb={2}
                  sx={{
                    borderBottom: "2px solid #e0e0e0",
                    paddingBottom: "8px",
                  }}
                >
                  Shipping
                </Typography>
                <Typography variant="body2">
                  {invoiceData?.shipping_address?.name}
                </Typography>
                <Typography variant="body2">
                  {invoiceData.shipping_address?.address_line_1},{" "}
                  {invoiceData.shipping_address?.address_line_2}
                </Typography>
                <Typography variant="body2">
                  GST Number: {invoiceData.shipping_address?.gst_number}
                </Typography>
                <Typography variant="body2">
                  Vehicle Number:{" "}
                  {invoiceData.billing_address?.motor_vehicle_number}
                </Typography>
              </Grid>
            </Grid>

            <Divider sx={{ my: 1 }} />

            {/* Product Details Table */}
            <Table sx={{ borderCollapse: "collapse", width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center">S. No.</TableCell>
                  {/* <TableCell align="center">Product Serial number</TableCell> */}
                  <TableCell align="center">Description of Goods</TableCell>
                  <TableCell align="center">HSN/SAC</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="center">Rate</TableCell>
                  <TableCell align="center">GST</TableCell>
                  <TableCell align="center">Amount</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {invoiceData.products?.map((product, index) => (
                  <React.Fragment key={index}>
                    {/* Product Row */}
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {product.product_name} ({product.product_serial_number})
                      </TableCell>
                      <TableCell align="center">{product.hsn_code}</TableCell>
                      <TableCell align="center">{product.quantity}</TableCell>
                      <TableCell align="center">
                        ₹{parseFloat(product.price).toFixed(2)}
                      </TableCell>
                      <TableCell align="center">
                        ₹{parseFloat(product.gst_amount).toFixed(2)}
                      </TableCell>
                      <TableCell align="center">
                        ₹{parseFloat(product.amount).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}

                {/* Render Items (outside of products) */}
                {invoiceData.items?.map((item, index) => (
                  <TableRow key={`item-${index}`}>
                    <TableCell align="center">{index + 2}</TableCell>{" "}
                    {/* Empty for indentation */}
                    <TableCell align="center">{item.id}</TableCell>
                    <TableCell align="center">{item.hsn_code}</TableCell>
                    <TableCell align="center">{item.quantity}</TableCell>
                    <TableCell align="center">
                      ₹{parseFloat(item.sell_price).toFixed(2)}
                    </TableCell>
                    <TableCell align="center">
                      ₹{parseFloat(item.gst_amount).toFixed(2)}
                    </TableCell>
                    <TableCell align="center">
                      ₹{parseFloat(item.amount).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}

                {/* Render Subitems (outside of products) */}
                {invoiceData.sub_items?.map((subItem, index) => (
                  <TableRow key={`subitem-${index}`}>
                    <TableCell align="center">{index + 3}</TableCell>{" "}
                    {/* Empty for indentation */}
                    <TableCell align="center">{subItem.id}</TableCell>
                    <TableCell align="center">{subItem.hsn_code}</TableCell>
                    <TableCell align="center">{subItem.quantity}</TableCell>
                    <TableCell align="center">
                      ₹{parseFloat(subItem.sell_price).toFixed(2)}
                    </TableCell>
                    <TableCell align="center">
                      ₹{parseFloat(subItem.gst_amount).toFixed(2)}
                    </TableCell>
                    <TableCell align="center">
                      ₹{parseFloat(subItem.amount).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* Total Section */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" fontWeight="bold">
                  Amount Chargeable (in words):
                </Typography>
                <Typography variant="body2" fontStyle="italic">
                  {invoiceData.total_amount_words || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  Taxable Value: ₹
                  {parseFloat(
                    invoiceData.total_amount - invoiceData.shipping_amount
                  ).toFixed(2)}
                </Typography>
                <Typography variant="body2">
                  Shipping Amount: ₹
                  {parseFloat(invoiceData.shipping_amount).toFixed(2)}
                </Typography>
                <Typography variant="h6" fontWeight="bold" textAlign="right">
                  Total: ₹{parseFloat(invoiceData.total_amount).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Typography>No invoice data available.</Typography>
        )}
      </DialogContent>

      {/* Footer */}
      <DialogActions sx={{ padding: "16px" }}>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Close
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => alert("Download functionality to be added")}
        >
          Download Invoice
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Invoice;
// import React, { useState, useEffect } from "react";
// import {
//   Dialog,
//   DialogContent,
//   DialogTitle,
//   DialogActions,
//   Button,
//   CircularProgress,
//   Typography,
//   Grid,
//   Box,
//   Table,
//   TableHead,
//   TableRow,
//   TableCell,
//   TableBody,
//   Divider,
//   Paper,
// } from "@mui/material";
// import { styled } from "@mui/material/styles";
// import { toast } from "react-toastify";
// import { getReq } from "../../../apis/api";

// const StyledPaper = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(3),
//   marginBottom: theme.spacing(3),
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: theme.shadows[1],
// }));

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   backgroundColor: theme.palette.primary.main,
//   color: theme.palette.common.white,
// }));

// const Invoice = ({ open, handleClose, ItemId }) => {
//   const [invoiceData, setInvoiceData] = useState(null);
//   const [settingData, setSettingData] = useState(null);
//   const [loading, setLoading] = useState(true);

//   const fetchInvoice = async () => {
//     try {
//       setLoading(true);
//       const res = await getReq(`/orderInvoice/${ItemId}`);
//       console.log("Response received:", res);

//       const invoice = res?.data?.[0];
//       setInvoiceData(invoice);

//       const settingData = res?.settingdata || [];
//       setSettingData(settingData);

//       if (settingData.length > 0) {
//         console.log("Setting Data:", settingData);
//       } else {
//         console.log("No setting data found");
//       }
//     } catch (error) {
//       console.error("Error fetching invoice data:", error);
//       toast.error("Error in fetching invoice data");
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (open && ItemId) {
//       console.log(`Invoice modal is open, ItemId: ${ItemId}`);
//       fetchInvoice();
//     }
//   }, [open, ItemId]);

//   if (!open) return null;

//   console.log("Rendering Invoice Dialog", invoiceData);

//   return (
//     <Dialog
//       open={open}
//       onClose={handleClose}
//       fullWidth
//       maxWidth="md"
//       PaperProps={{
//         style: {
//           borderRadius: 16,
//           boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
//         },
//       }}
//     >
//       <DialogTitle
//         sx={{
//           textAlign: "center",
//           fontWeight: "bold",
//           fontSize: "2rem",
//           color: "primary.main",
//         }}
//       >
//         Tax Invoice
//       </DialogTitle>

//       <Divider />

//       <DialogContent sx={{ padding: "20px" }}>
//         {loading ? (
//           <Box
//             display="flex"
//             justifyContent="center"
//             alignItems="center"
//             height="300px"
//           >
//             <CircularProgress />
//           </Box>
//         ) : invoiceData ? (
//           <Box>
//             <StyledPaper elevation={3}>
//               <Grid container spacing={3}>
//                 <Grid item xs={4}>
//                   <Typography variant="body1" fontWeight="bold" color="primary">
//                     Invoice Number:
//                   </Typography>
//                   <Typography variant="body1">
//                     {invoiceData.invoice_number}
//                   </Typography>
//                 </Grid>
//                 <Grid item xs={4}>
//                   <Typography variant="body1" fontWeight="bold" color="primary">
//                     E-way Bill Number:
//                   </Typography>
//                   <Typography variant="body1">
//                     {invoiceData.eway_bill_number}
//                   </Typography>
//                 </Grid>
//                 <Grid item xs={4}>
//                   <Typography variant="body1" fontWeight="bold" color="primary">
//                     Order Date:
//                   </Typography>
//                   <Typography variant="body1">
//                     {new Date(invoiceData.order_date).toLocaleDateString()}
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </StyledPaper>

//             <Grid container spacing={3}>
//               {settingData && settingData.length > 0 ? (
//                 settingData.map((company) => (
//                   <Grid item xs={12} sm={4} key={company.id}>
//                     <StyledPaper elevation={3}>
//                       <Typography
//                         variant="h6"
//                         fontWeight="bold"
//                         color="primary"
//                       >
//                         Company Details
//                       </Typography>
//                       <Typography variant="body1" fontWeight="bold">
//                         {company.company_name}
//                       </Typography>
//                       <Typography variant="body2">
//                         {company.company_address1}, {company.company_address2}
//                       </Typography>
//                       <Typography variant="body2">
//                         {company.company_phone1}
//                       </Typography>
// <Typography variant="body2">
//   <span className="font-semibold text-gray-700">GST No:</span>
//   <span className="text-primary font-normal ml-1">
//     {company.gstin_number || "N/A"}
//   </span>
// </Typography>

// <Typography variant="body2">
//   <span className="font-semibold text-gray-700">State:</span>
//   <span className="text-primary font-normal ml-1">
//     {company.state || "N/A"}
//   </span>
// </Typography>

// <Typography variant="body2">
//   <span className="font-semibold text-gray-700">Email:</span>
//   <span className="text-primary font-normal ml-1">
//     {company.company_email || "N/A"}
//   </span>
// </Typography>

//                     </StyledPaper>
//                   </Grid>
//                 ))
//               ) : (
//                 <Typography>No company details available.</Typography>
//               )}

//               <Grid item xs={12} sm={4}>
//                 <StyledPaper elevation={3}>
//                   <Typography variant="h6" fontWeight="bold" color="primary">
//                     Billing
//                   </Typography>
//                   <Typography variant="body1">
//                     {invoiceData.billing_address?.name}
//                   </Typography>
//                   <Typography variant="body2">
//                     {invoiceData.billing_address?.address_line_1},{" "}
//                     {invoiceData.billing_address?.address_line_2}
//                   </Typography>
//                   <Typography variant="body2">
//                     <span className="font-semibold text-gray-700">GST No:</span>
//                     <span className="text-primary font-normal ml-1">
//                       {invoiceData.billing_address?.gst_number || "N/A"}
//                     </span>
//                   </Typography>

//                   <Typography variant="body2">
//                     <span className="font-semibold text-gray-700">State:</span>
//                     <span className="text-primary font-normal ml-1">
//                       {invoiceData.billing_address?.state || "N/A"}
//                     </span>
//                   </Typography>

//                   <Typography variant="body2">
//                     <span className="font-semibold text-gray-700">
//                       Contact:
//                     </span>
//                     <span className="text-primary font-normal ml-1">
//                       {invoiceData.billing_address?.phone || "N/A"}
//                     </span>
//                   </Typography>

//                   <Typography variant="body2">
//                     <span className="font-semibold text-gray-700">
//                       Vehicle No:
//                     </span>
//                     <span className="text-primary font-normal ml-1">
//                       {invoiceData.billing_address?.motor_vehicle_number ||
//                         "N/A"}
//                     </span>
//                   </Typography>
//                 </StyledPaper>
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <StyledPaper elevation={3}>
//                   <Typography variant="h6" fontWeight="bold" color="primary">
//                     Shipping
//                   </Typography>
//                   <Typography variant="body1">
//                     {invoiceData?.shipping_address?.name}
//                   </Typography>
//                   <Typography variant="body2">
//                     {invoiceData.shipping_address?.address_line_1},{" "}
//                     {invoiceData.shipping_address?.address_line_2}
//                   </Typography>
//                   <Typography variant="body2">
//                     <span className="font-semibold text-gray-700">GST No:</span>
//                     <span className="text-primary font-normal ml-1">
//                       {invoiceData.shipping_address?.gst_number}
//                     </span>
//                   </Typography>
//                   <Typography variant="body2">
//                     <span className="font-semibold text-gray-700">
//                       Vehicle Number:
//                     </span>
//                     <span className="text-primary font-normal ml-1">
//                       {invoiceData.billing_address?.motor_vehicle_number ||
//                         "N/A"}
//                     </span>
//                   </Typography>
//                 </StyledPaper>
//               </Grid>
//             </Grid>

//             <StyledPaper elevation={3}>
//               <Table
//                 sx={{ borderCollapse: "separate", borderSpacing: "0 8px" }}
//               >
//                 <TableHead>
//                   <TableRow>
//                     <StyledTableCell align="center">S. No.</StyledTableCell>
//                     <StyledTableCell align="center">
//                       Description of Goods
//                     </StyledTableCell>
//                     <StyledTableCell align="center">HSN/SAC</StyledTableCell>
//                     <StyledTableCell align="center">Quantity</StyledTableCell>
//                     <StyledTableCell align="center">Rate</StyledTableCell>
//                     <StyledTableCell align="center">GST</StyledTableCell>
//                     <StyledTableCell align="center">Amount</StyledTableCell>
//                   </TableRow>
//                 </TableHead>

//                 <TableBody>
//                   {invoiceData.products?.map((product, index) => (
//                     <TableRow
//                       key={index}
//                       sx={{
//                         "&:nth-of-type(odd)": {
//                           backgroundColor: "action.hover",
//                         },
//                       }}
//                     >
//                       <TableCell align="center">{index + 1}</TableCell>
//                       <TableCell align="center">
//                         {product.product_name} ({product.product_serial_number})
//                       </TableCell>
//                       <TableCell align="center">{product.hsn_code}</TableCell>
//                       <TableCell align="center">{product.quantity}</TableCell>
//                       <TableCell align="center">
//                         ₹{parseFloat(product.price).toFixed(2)}
//                       </TableCell>
//                       <TableCell align="center">
//                         ₹{parseFloat(product.gst_amount).toFixed(2)}
//                       </TableCell>
//                       <TableCell align="center">
//                         ₹{parseFloat(product.amount).toFixed(2)}
//                       </TableCell>
//                     </TableRow>
//                   ))}

//                   {invoiceData.items?.map((item, index) => (
//                     <TableRow
//                       key={`item-${index}`}
//                       sx={{
//                         "&:nth-of-type(odd)": {
//                           backgroundColor: "action.hover",
//                         },
//                       }}
//                     >
//                       <TableCell align="center">{index + 2}</TableCell>
//                       <TableCell align="center">{item.id}</TableCell>
//                       <TableCell align="center">{item.hsn_code}</TableCell>
//                       <TableCell align="center">{item.quantity}</TableCell>
//                       <TableCell align="center">
//                         ₹{parseFloat(item.sell_price).toFixed(2)}
//                       </TableCell>
//                       <TableCell align="center">
//                         ₹{parseFloat(item.gst_amount).toFixed(2)}
//                       </TableCell>
//                       <TableCell align="center">
//                         ₹{parseFloat(item.amount).toFixed(2)}
//                       </TableCell>
//                     </TableRow>
//                   ))}

//                   {invoiceData.sub_items?.map((subItem, index) => (
//                     <TableRow
//                       key={`subitem-${index}`}
//                       sx={{
//                         "&:nth-of-type(odd)": {
//                           backgroundColor: "action.hover",
//                         },
//                       }}
//                     >
//                       <TableCell align="center">{index + 3}</TableCell>
//                       <TableCell align="center">{subItem.id}</TableCell>
//                       <TableCell align="center">{subItem.hsn_code}</TableCell>
//                       <TableCell align="center">{subItem.quantity}</TableCell>
//                       <TableCell align="center">
//                         ₹{parseFloat(subItem.sell_price).toFixed(2)}
//                       </TableCell>
//                       <TableCell align="center">
//                         ₹{parseFloat(subItem.gst_amount).toFixed(2)}
//                       </TableCell>
//                       <TableCell align="center">
//                         ₹{parseFloat(subItem.amount).toFixed(2)}
//                       </TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </StyledPaper>

//             <StyledPaper elevation={3} sx={{ mt: 3 }}>
//               <Grid container spacing={2}>
//                 <Grid item xs={12} sm={6}>
//                   <Typography variant="body1" fontWeight="bold" color="primary">
//                     Amount Chargeable (in words):
//                   </Typography>
//                   <Typography variant="body1" fontStyle="italic">
//                     {invoiceData.total_amount_words || "N/A"}
//                   </Typography>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <Typography variant="body1">
//                     Taxable Value: ₹
//                     {parseFloat(
//                       invoiceData.total_amount - invoiceData.shipping_amount
//                     ).toFixed(2)}
//                   </Typography>
//                   <Typography variant="body1">
//                     Shipping Amount: ₹
//                     {parseFloat(invoiceData.shipping_amount).toFixed(2)}
//                   </Typography>
//                   <Typography
//                     variant="h5"
//                     fontWeight="bold"
//                     textAlign="right"
//                     color="primary"
//                   >
//                     Total: ₹{parseFloat(invoiceData.total_amount).toFixed(2)}
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </StyledPaper>
//           </Box>
//         ) : (
//           <Typography>No invoice data available.</Typography>
//         )}
//       </DialogContent>

//       <DialogActions sx={{ padding: "16px" }}>
//         <Button onClick={handleClose} color="secondary" variant="outlined">
//           Close
//         </Button>
//         <Button
//           color="primary"
//           variant="contained"
//           onClick={() => alert("Download functionality to be added")}
//         >
//           Download Invoice
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default Invoice;
