import ProtectedRoute from "./ProtectedRoute"; // Assuming it's in the same directory
import Login from "../pages/login";
import React from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomePage from "../pages/home/Home"; // Make sure this path is correct
import Dashboard from "../pages/dashboard";
import PageNotFound from "../pages/pageNotFound/Index";
import ProductPerformance from "../pages/dashboard/ProductPerformance";
import User from "../pages/userManagement";
import CreateUser from "../components/Modal/user/Create";
import CreateNewUser from "../pages/userManagement/CreateNewUser";
import OpenRoute from "./OpenRoute";
import Landingpage from "../pages/landingpage/Landingpage";
import Profile from "../pages/profile/Profile";
import UserDetails from "../pages/userManagement/UserDetails";
import UserTable from "../pages/userManagement/UserTable";
import Access from "../pages/access/Index";
import AllRoleAccess from "../pages/access/AllRoleAccess";
import Setting from "../pages/profile/Setting";
import Cookies from "js-cookie";
import Items from "../pages/items/index";
import Products from "../pages/products/index";
import ManageCategory from "../pages/items/ManageCategory";
import AllItems from "../pages/items/AllItems";
import AllSubItems from "../pages/subItems/AllSubItems";
import SubItems from "../pages/subItems/Index";
import SessionTimeout from "../components/sessionScreen/SessionTimeout";
import Index from "../pages/purchase";
import AllPurchaseOrders from "../pages/purchase/AllPurchaseOrders";
import PurchaseOrderForm from "../pages/purchase/ViewAndEdit";
import Category from "../pages/products/Category";
import Management from "../pages/products/Management";
import CreateProduct from "../pages/products/CreateProduct"; 
import Orders from "../pages/orders/index"
import OrderManagement from "../pages/orders/OrderManagement";
import CreateOrder from "../pages/orders/CreateOrder";
import DealerManagement from "../pages/orders/DealerManagement";

const AppRoutes = () => {
  const token = Cookies.get("token");

  return (
    <div className="m-0 p-0 bg-[#f9f9fb]">
      <ToastContainer />
      <Routes>
        <Route path="/" element={token ? <HomePage /> : <Landingpage />} />
        <Route path="/login" element={<Login />} />

        <Route path="/" element={<ProtectedRoute element={<HomePage />} />}>
          <Route path="dashboard" element={<Dashboard />}>
            {/* Nested route */}
            <Route
              path="product-performance"
              element={<ProductPerformance />}
            />
            <Route path="missed-delivery" element={<PageNotFound />} />
            <Route path="latest-orders" element={<PageNotFound />} />
          </Route>
          <Route path="users" element={<User />}>
            {/* Nested route */}
            <Route path="" element={<UserTable />} />
            <Route path="users-list" element={<UserTable />} />

            <Route path="users-profile" element={<PageNotFound />} />
          </Route>
          <Route path="access-management" element={<Access />}>
            {/* Nested route */}
            <Route path="" element={<AllRoleAccess />} />
          </Route>

          <Route path="purchases" element={<Index />}>
            {/* Nested route */}
            <Route path="" element={<AllPurchaseOrders />} />
            <Route path="purchase-order" element={<AllPurchaseOrders />} />
            <Route
              path="edit-purchase-order/:id"
              element={<PurchaseOrderForm />}
            />
          </Route>

          <Route path="items" element={<Items />}>
            {/* Nested route */}
            <Route path="" element={<AllItems />} />
            <Route path="categories" element={<ManageCategory />} />
            <Route path="subitems" element={<AllSubItems />} />
          </Route>
          {/* product route */}
          <Route path="manufacturing" element={<Products />}>
            {/* Nested route */}
            <Route path="" element={<Management />} />
            <Route path="add-product" element={<Category />} />
            <Route path="products-management" element={<Management />} />
            <Route path="create-product" element={<CreateProduct />} />
            <Route path="edit-product/:productId" element={<CreateProduct />} />
          </Route> 
           

           {/* order routes  */}
           <Route path="sales-service" element={<Orders/>}>
            {/* Nested route */} 

            {/* <Route path="" element={<Management />} /> */}
            <Route path="dealer-order" element={<DealerManagement />} />
            <Route path="manage-orders" element={<OrderManagement />} />
            <Route path="create-order" element={<CreateOrder />} />
            <Route path="edit-order/:orderId" element={<CreateOrder />} /> 
            <Route path="create-quotation/:orderId" element={<CreateOrder />} />
          </Route>





          {/* profile Route  */}
          <Route path="users/user-detail/:id" element={<UserDetails />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/settings/:id" element={<Setting />} />
        </Route>

        {/* profile route  */}
        <Route path="/session-timeout" element={<SessionTimeout />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};
export default AppRoutes;
