import {
  Divider,
  Grid,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  FormControl,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { addDays, format } from "date-fns";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { Controller } from "react-hook-form";
import {
  CalendarToday,
  Receipt,
  Assignment,
  LocalShipping,
  FormatListNumbered,
  GpsFixed,
  Phone,
  Email,
  LocationOn,
  Public,
  Description,
  Business,
  Person,
  CurrencyRupee,
  Percent,
  Category,
  Discount,
  Scale,
} from "@mui/icons-material";
import PercentIcon from "@mui/icons-material/Percent";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AdjustIcon from "@mui/icons-material/Adjust";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import NoteIcon from "@mui/icons-material/Note";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { getReq, jsonpostReq, nestedPostReq, rawPostReq } from "../../apis/api";
import { toast } from "react-toastify";
import { BsPlusCircleDotted } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import FormButton from "../../components/Button";
import Loader from "../../components/loader/Loading";
import { orderSchema } from "../../utils/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { toWords } from "number-to-words";

const CreateOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orderId } = useParams();
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [items, setItems] = useState([]);
  const [subItems, setSubitems] = useState([]);
  const [productSerialNumber, setProductSerialNumber] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const todayDate = format(new Date(), "yyyy-MM-dd");
  const estimatedDeliveryDate = format(addDays(new Date(), 3), "yyyy-MM-dd");

  // Determine the mode based on the path
  const isCreateOrder = location.pathname === "/create-order";
  const isEditOrder = location.pathname.includes("/edit-order");
  const isCreateQuotation = location.pathname.includes("/create-quotation");

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(orderSchema),
    defaultValues: {
      invoice_number: "",
      order_date: todayDate,
      tally_invoice_number: "",
      eway_bill_number: "",
      user_id: "",
      billing_address: {
        name: "",
        company: "",
        gst_number: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        post_code: "",
        country: "",
        state: "",
        email: "",
        phone: "",
        dispatch_destination: "",
        motor_vehicle_number: "",
      },
      shipping_address: {
        name: "",
        company: "",
        gst_number: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        post_code: "",
        country: "",
        state: "",
        email: "",
        phone: "",
        dispatch_destination: "",
        motor_vehicle_number: "",
      },
      products: [
        {
          serial_number: "",
          id: "",
          hsn_code: "",
          product_serial_number: "",
          quantity: "1",
          sgew_price: "",
          gst_amount: "",
          gst_percent: "",
          discount: "",
          amount: "",
        },
      ],
      items: [
        {
          serial_number: "",
          id: "",
          hsn_code: "",
          quantity: "",
          sell_price: "",
          gst_amount: "",
          gst_percent: "",
          discount: "",
          amount: "",
        },
      ],
      sub_items: [
        {
          serial_number: "",
          id: "",
          hsn_code: "",
          quantity: "",
          sell_price: "",
          gst_amount: "",
          gst_percent: "",
          discount: "",
          amount: "",
        },
      ],
      discount: "",
      shipping_amount: "",
      rounded_off_amount: "",
      total_amount: "",
      total_amount_words: "",
      order_note: "Handle with care",
      estimated_delivery_date: estimatedDeliveryDate,
    },
  });

  const {
    fields: productFields,
    append: addProduct,
    remove: removeProduct,
  } = useFieldArray({
    control,
    name: "products",
  });
  const {
    fields: itemFields,
    append: addItem,
    remove: removeItem,
  } = useFieldArray({
    control,
    name: "items",
  });

  const {
    fields: subItemFields,
    append: addSubItem,
    remove: removeSubItem,
  } = useFieldArray({
    control,
    name: "sub_items",
  });

  const fetchCustomers = async () => {
    try {
      const res = await getReq("/fetchCustomer");
      setCustomers(res.data || []);
    } catch (e) {
      console.error("Error fetching categories:", e);
      toast.error("Error in fetching categories");
    }
  };
  const fetchProducts = async () => {
    try {
      const res = await getReq("/products");
      setProducts(res.data || []);
    } catch (e) {
      console.error("Error fetching Items:", e);
      toast.error("Error in fetching Items");
    }
  };
  const fetchItems = async () => {
    try {
      const res = await getReq("/fetchItems");
      setItems(res.data || []);
    } catch (e) {
      console.error("Error fetching Items:", e);
      toast.error("Error in fetching Items");
    }
  };

  const fetchSubitems = async () => {
    try {
      const res = await getReq("/subitems");
      setSubitems(res.data || []);
    } catch (e) {
      console.error("Error fetching Items:", e);
      toast.error("Error in fetching Items");
    }
  };

  const fetchOrderData = async () => {
    try {
      const res = await getReq(`/fetchSingleOrder/${orderId}`);
      const productData = res.data[0]; // Get the first item from the response array

      // Prepare the form data with the structure that matches the defaultValues in your useForm hook
      const formData = {
        invoice_number: productData.invoice_number,
        order_date: productData.order_date,
        tally_invoice_number: productData.tally_invoice_number,
        eway_bill_number: productData.eway_bill_number,
        user_id: productData.user_id,
        billing_address: productData.billing_address,
        shipping_address: productData.shipping_address,
        products: productData.products,
        items: productData.items,
        sub_items: productData.sub_items,
        discount: productData.discount,
        shipping_amount: productData.shipping_amount,
        rounded_off_amount: productData.rounded_off_amount,
        total_amount: productData.total_amount,
        total_amount_words: productData.total_amount_words,
        order_note: productData.order_note,
        estimated_delivery_date: productData.estimated_delivery_date,
      };

      // Reset the form with the fetched data
      reset(formData);
    } catch (error) {
      console.error("Error fetching order data:", error);
      toast.error("Failed to load order data");
    }
  };

  const selectedUserId = watch("user_id");

  useEffect(() => {
    if (selectedUserId) {
      const selectedCustomer = customers.find(
        (customer) => customer.id === selectedUserId
      );

      if (selectedCustomer) {
        setValue("billing_address.name", selectedCustomer.user_name || "");
        // setValue("billing_address.company", selectedCustomer.company || "");
        setValue(
          "billing_address.gst_number",
          selectedCustomer.gstin_number || ""
        );
        setValue(
          "billing_address.address_line_1",
          selectedCustomer.address_line_1 || ""
        );
        setValue(
          "billing_address.address_line_2",
          selectedCustomer.address_line_2 || ""
        );
        setValue("billing_address.city", selectedCustomer.village || "");
        // setValue("billing_address.post_code", selectedCustomer.post_code || "");
        setValue("billing_address.country", selectedCustomer.country || "");
        setValue("billing_address.state", selectedCustomer.state || "");
        setValue("billing_address.email", selectedCustomer.email || "");
        setValue("billing_address.phone", selectedCustomer.phone || "");
      }
    }

    if (selectedUserId) {
      const selectedCustomer = customers.find(
        (customer) => customer.id === selectedUserId
      );

      if (selectedCustomer) {
        setValue("shipping_address.name", selectedCustomer.user_name || "");
        // setValue("billing_address.company", selectedCustomer.company || "");
        setValue(
          "shipping_address.gst_number",
          selectedCustomer.gstin_number || ""
        );
        setValue(
          "shipping_address.address_line_1",
          selectedCustomer.address_line_1 || ""
        );
        setValue(
          "shipping_address.address_line_2",
          selectedCustomer.address_line_2 || ""
        );
        setValue("shipping_address.city", selectedCustomer.village || "");
        // setValue("billing_address.post_code", selectedCustomer.post_code || "");
        setValue("shipping_address.country", selectedCustomer.country || "");
        setValue("shipping_address.state", selectedCustomer.state || "");
        setValue("shipping_address.email", selectedCustomer.email || "");
        setValue("shipping_address.phone", selectedCustomer.phone || "");
      }
    }
  }, [selectedUserId, customers, setValue]);

  useEffect(() => {
    fetchCustomers();
    fetchProducts();
    fetchItems();
    fetchSubitems();
    if (orderId) {
      fetchOrderData();
    }
  }, [orderId]);

  const handleProductChange = async (index, selectedProductId) => {
    const item = products.find((i) => i.id === selectedProductId);

    if (item) {
      const sgewPrice = parseFloat(item.sgew_price) || 10000;
      const discount = 0;
      const gstPercentage = parseFloat(item.gst_percentage) || 0;
      const netPrice = sgewPrice - discount;
      const gstAmount = calculateGstAmount(netPrice, gstPercentage);
      const totalAmount = netPrice + parseFloat(gstAmount);

      setValue(`products.${index}.serial_number`, Number(item.id));
      setValue(
        `products.${index}.product_serial_number`,
        item.product_serial_number
      );
      setValue(`products.${index}.id`, Number(item.id));
      setValue(`products.${index}.product_name`, item.product_name);
      setValue(`products.${index}.hsn_code`, item.hsn_code);
      setValue(`products.${index}.sgew_price`, sgewPrice);
      setValue(`products.${index}.gst_percent`, gstPercentage);
      setValue(`products.${index}.gst_amount`, gstAmount);
      setValue(`products.${index}.discount`, discount);
      setValue(`products.${index}.amount`, totalAmount);

      try {
        const res = await getReq(`/fetchProductSerial/${selectedProductId}`);
        if (res.data && res.data.product_serial_number) {
          setProductSerialNumber(res.data.product_serial_number);
          setValue(`products.${index}.product_serial_number`, "");
        } else {
          setProductSerialNumber([]);
        }
      } catch (error) {
        console.error("Error fetching product serial numbers:", error);
        toast.error("Failed to fetch product serial numbers.");
        setProductSerialNumber([]);
      }
    }
  };

  const calculateGstAmount = (amount, gstPercentage) => {
    return ((amount * gstPercentage) / 100).toFixed(2);
  };

  const calculateValues = (index) => {
    const quantity = watch(`items.${index}.quantity`) || 1; // Default to 1
    const discount = watch(`items.${index}.discount`) || 0; // Default to 0
    const sellPrice = watch(`items.${index}.sell_price`) || 200; // Use backend value or fallback
    const gstPercent = watch(`items.${index}.gst_percent`) || 18; // Use backend value or fallback

    const totalSellPrice = sellPrice * quantity;
    const gstAmount = ((totalSellPrice - discount) * gstPercent) / 100;
    const amount = totalSellPrice - discount + gstAmount;

    setValue(`items.${index}.gst_amount`, gstAmount);
    setValue(`items.${index}.amount`, amount);
  };

  const calculateSubItemValues = (index) => {
    const quantity = watch(`sub_items.${index}.quantity`) || 1; // Default to 1
    const discount = watch(`sub_items.${index}.discount`) || 0; // Default to 0
    const sellPrice = watch(`sub_items.${index}.sell_price`) || 200; // Use backend value or fallback
    const gstPercent = watch(`sub_items.${index}.gst_percent`) || 18; // Use backend value or fallback

    const totalSellPrice = sellPrice * quantity;
    const gstAmount = ((totalSellPrice - discount) * gstPercent) / 100;
    const amount = totalSellPrice - discount + gstAmount;

    // Update form values dynamically
    setValue(`sub_items.${index}.gst_amount`, gstAmount);
    setValue(`sub_items.${index}.amount`, amount);
  };

  const handleDiscountChange = (index, discountValue) => {
    const sgewPrice = parseFloat(watch(`products.${index}.sgew_price`)) || 0; // Original price
    const gstPercentage =
      parseFloat(watch(`products.${index}.gst_percent`)) || 0; // GST %

    // Parse and validate the discount value
    const discount = parseFloat(discountValue) || 0;

    // Calculate net price after discount
    const netPrice = sgewPrice - discount;

    // Calculate GST amount
    const gstAmount = calculateGstAmount(netPrice, gstPercentage);
    // Calculate total amount (net price + GST)
    const totalAmount = netPrice + parseFloat(gstAmount);

    // Update the values in the form state
    setValue(`products.${index}.gst_amount`, gstAmount);
    setValue(`products.${index}.amount`, totalAmount);
  };

  const handleItemChange = (index, selectedItem) => {
    const item = items.find((i) => i.item_id === selectedItem);

    if (item) {
      const sellPrice = parseFloat(item.sell_price) || 200; // Use backend sell price or fallback to 200
      const gstPercent = parseFloat(item.default_tax_percentage) || 10; // Use backend GST or fallback to 18
      const defaultQuantity = 1;
      const defaultDiscount = 0;

      // Set initial values
      setValue(`items.${index}.id`, Number(item.item_id));
      setValue(`items.${index}.hsn_code`, item.hsn); 
      
      setValue(`items.${index}.quantity`, defaultQuantity);
      setValue(`items.${index}.sell_price`, sellPrice);
      setValue(`items.${index}.discount`, defaultDiscount);

      // Calculate initial GST and amount
      const gstAmount = (sellPrice * gstPercent) / 100;
      const amount = sellPrice + gstAmount;

      setValue(`items.${index}.gst_percent`, gstPercent);
      setValue(`items.${index}.gst_amount`, gstAmount);
      setValue(`items.${index}.amount`, amount);
    }
  };

  const handleSubItemChange = (index, selectedSubItem) => {
    const subItem = subItems.find((i) => i.id === selectedSubItem);

    if (subItem) {
      const sellPrice = parseFloat(subItem.sell_price) || 150; // Use backend sell price or fallback to 150
      const gstPercent = parseFloat(subItem.default_tax_percentage) || 12; // Use backend GST or fallback to 12
      const defaultQuantity = 1;
      const defaultDiscount = 0;

      // Calculate initial GST and amount
      const gstAmount = (sellPrice * gstPercent) / 100;
      const amount = sellPrice + gstAmount;

      // Set values for each required field in the form
      setValue(`sub_items.${index}.id`, subItem.id);
      setValue(`sub_items.${index}.hsn_code`, subItem.hsn_code || subItem.hsn); // Use `hsn_code` if available, else fallback to `hsn`
      setValue(`sub_items.${index}.quantity`, defaultQuantity);
      setValue(`sub_items.${index}.sell_price`, sellPrice);
      setValue(`sub_items.${index}.discount`, defaultDiscount);
      setValue(`sub_items.${index}.gst_percent`, gstPercent);
      setValue(`sub_items.${index}.gst_amount`, gstAmount);
      setValue(`sub_items.${index}.amount`, amount);
      setValue(
        `sub_items.${index}.sgew_price`,
        parseFloat(subItem.sgew_price) || 0
      ); // Fallback to 0 if `sgew_price` is unavailable
    }
  };

  // Watch the form fields
  const productsuse = watch("products");
  const itemsuse = watch("items");
  const subItemsuse = watch("sub_items");
  const shippingAmountuse = watch("shipping_amount");

  useEffect(() => {
    const calculateTotals = () => {
      // Calculate total discount across all products, items, and sub-items
      const totalDiscount = [
        ...productsuse.map((p) => parseFloat(p.discount) || 0),
        ...itemsuse.map((i) => parseFloat(i.discount) || 0),
        ...subItemsuse.map((s) => parseFloat(s.discount) || 0),
      ].reduce((acc, discount) => acc + discount, 0);

      // Calculate total amount before shipping
      const totalAmountBeforeShipping = [
        ...productsuse.map((p) => parseFloat(p.amount) || 0),
        ...itemsuse.map((i) => parseFloat(i.amount) || 0),
        ...subItemsuse.map((s) => parseFloat(s.amount) || 0),
      ].reduce((acc, amount) => acc + amount, 0);

      // Add the shipping amount entered by the user
      const totalAmount =
        totalAmountBeforeShipping + parseFloat(shippingAmountuse || 0); // Add shipping amount

      // Round off total amount to nearest integer
      const roundedOffAmount = Math.round(totalAmount);

      // Set values to the form fields
      setValue("discount", totalDiscount);
      setValue("total_amount", totalAmount);
      setValue("rounded_off_amount", roundedOffAmount);

      // Convert total amount to words (optional)
      const totalAmountWords = convertToWords(totalAmount);
      setValue("total_amount_words", totalAmountWords); // Total Amount in Words
    };

    calculateTotals();
  }, [productsuse, itemsuse, subItemsuse, shippingAmountuse]);

  const convertToWords = (amount) => {
    return toWords(amount); // Using the 'number-to-words' library to convert number to words
  };

  const getApiUrl = () => {
    if (isEditOrder) return `/orders/${orderId}/update`;
    if (isCreateQuotation) return `/orders/createQuote/${orderId}`;
    return "/orders/create"; // Default for creating a new order
  };

  const getButtonText = () => {
    if (isEditOrder) return "Update Order";
    if (isCreateQuotation) return "Create Quotation";
    return "Create Order";
  };
  const onSubmit = async (data) => {
    console.log("order data string", JSON.stringify(data));
    setIsLoading(true);

    const apiUrl = getApiUrl(); // Determine API endpoint

    try {
      let response;
 
      // Decide which API call to make
      if (isCreateQuotation) {
        // Explicitly use rawPostReq for createQuote API
        response = await jsonpostReq(data, apiUrl);
      } else if (isEditOrder) {
        response = await jsonpostReq(data, apiUrl);
      } else {
        response = await jsonpostReq(data, apiUrl);
      }
      // Handle successful responses
      if (response.status === 200 || response.status === 201) {
        const successMessage = isEditOrder
          ? "Order Updated Successfully"
          : isCreateQuotation
          ? "Quotation Created Successfully"
          : "Order Placed Successfully!";
        toast.success(successMessage);

        reset(); // Assuming you have a form reset function
        navigate(-1); // Go back to the previous page
      } else {
        toast.error(response.data.message || "Operation failed.", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response?.data?.messages) {
        Object.values(error.response.data.messages).forEach((msg) => {
          toast.error(msg, {
            position: "top-right",
            autoClose: 5000,
          });
        });
      } else {
        toast.error("Something went wrong. Please try again.", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        width: "100%",
        margin: "0 auto",
        padding: "20px",
        background: "#f9f9f9",
        borderRadius: "8px",
      }}
    >
      <Grid container spacing={3}>
        {isLoading && <Loader />}
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "28px",
            padding: "20px",
          }}
        >
          {/* Back Icon Button */}
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => navigate(-1)} // Go back to the previous page
            aria-label="back"
            sx={{ position: "absolute", left: "20px", ml: "20px" }} // Add margin-left here
          >
            <ArrowBackIcon />
          </IconButton>

          {/* Product Information Text */}
          <Typography variant="h5" gutterBottom sx={{ flexGrow: 1 }}>
            Order Information
          </Typography>
          <Divider />
        </Grid>

        {/* invoice order information  */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="invoice_number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Invoice Number"
                placeholder="Enter invoice number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Receipt />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.invoice_number}
                helperText={errors.invoice_number?.message}
              />
            )}
          />
        </Grid>

        {/* Order Date */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="order_date"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                type="date"
                label="Order Date"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarToday />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.order_date}
                helperText={errors.order_date?.message}
              />
            )}
          />
        </Grid>

        {/* Tally Invoice Number */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="tally_invoice_number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Tally Invoice Number"
                placeholder="Enter tally invoice number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Assignment />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.tally_invoice_number}
                helperText={errors.tally_invoice_number?.message}
              />
            )}
          />
        </Grid>

        {/* Eway Bill Number */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="eway_bill_number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Eway Bill Number"
                placeholder="Enter eway bill number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalShipping />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.eway_bill_number}
                helperText={errors.eway_bill_number?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Billing Details
          </Typography>
          <Divider />
        </Grid>

        {/* customer name dropdown  */}
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth required variant="outlined">
            <Controller
              name="user_id"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Customer Name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FormatListNumbered />
                      </InputAdornment>
                    ),
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          maxHeight: 250,
                        },
                      },
                    },
                  }}
                  error={!!errors.user_id}
                  helperText={errors.user_id?.message}
                >
                  <MenuItem value="" disabled>
                    Select a Customer
                  </MenuItem>
                  {customers.map((customer) => (
                    <MenuItem key={customer.id} value={customer.id}>
                      {customer.user_name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </FormControl>
        </Grid>

        {/* Company */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="billing_address.company"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Company"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Business />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.company}
                helperText={errors.billing_address?.company?.message}
              />
            )}
          />
        </Grid>

        {/* GST Number */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="billing_address.gst_number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="GST Number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Description />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.gst_number}
                helperText={errors.billing_address?.gst_number?.message}
              />
            )}
          />
        </Grid>

        {/* Address Line 1 */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="billing_address.address_line_1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Address Line 1"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.address_line_1}
                helperText={errors.billing_address?.address_line_1?.message}
              />
            )}
          />
        </Grid>

        {/* Address Line 2 */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="billing_address.address_line_2"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Address Line 2"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.address_line_2}
                helperText={errors.billing_address?.address_line_2?.message}
              />
            )}
          />
        </Grid>

        {/* City */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="billing_address.city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="City"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.city}
                helperText={errors.billing_address?.city?.message}
              />
            )}
          />
        </Grid>

        {/* Postal Code */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="billing_address.post_code"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Postal Code"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <GpsFixed />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.post_code}
                helperText={errors.billing_address?.post_code?.message}
              />
            )}
          />
        </Grid>

        {/* Country */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="billing_address.country"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Country"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Public />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.country}
                helperText={errors.billing_address?.country?.message}
              />
            )}
          />
        </Grid>

        {/* State */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="billing_address.state"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="State"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.state}
                helperText={errors.billing_address?.state?.message}
              />
            )}
          />
        </Grid>

        {/* Email */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="billing_address.email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.email}
                helperText={errors.billing_address?.email?.message}
              />
            )}
          />
        </Grid>

        {/* Phone */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="billing_address.phone"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Phone"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.phone}
                helperText={errors.billing_address?.phone?.message}
              />
            )}
          />
        </Grid>

        {/* Dispatch Destination */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="billing_address.dispatch_destination"
            control={control}
            defaultValue="2"
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Dispatch Destination"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalShipping />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.dispatch_destination}
                helperText={
                  errors.billing_address?.dispatch_destination?.message
                }
              />
            )}
          />
        </Grid>

        {/* Motor Vehicle Number */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="billing_address.motor_vehicle_number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Motor Vehicle Number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <GpsFixed />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.motor_vehicle_number}
                helperText={
                  errors.billing_address?.motor_vehicle_number?.message
                }
              />
            )}
          />
        </Grid>

        {/* Products Information  */}
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Products Details
          </Typography>
          <Divider />
        </Grid>

        {productFields.map((product, index) => (
          <Grid container item spacing={2} key={product.id}>
            {/* Product ID */}
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth required variant="outlined">
                <Controller
                  name={`products.${index}.id`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Product Name"
                      placeholder="Select Product"
                      type="number"
                      onChange={(e) => {
                        field.onChange(e);
                        handleProductChange(index, e.target.value); // Auto-fill weight on selection
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Category />
                          </InputAdornment>
                        ),
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            sx: {
                              maxHeight: 250,
                            },
                          },
                        },
                      }}
                      error={!!errors.products?.[index]?.id}
                      helperText={errors.products?.[index]?.id?.message}
                    >
                      <MenuItem value="" disabled>
                        Select a Product
                      </MenuItem>
                      {/* Map through product options */}
                      {products.map((product) => (
                        <MenuItem key={product.id} value={product.id}>
                          {product.product_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormControl>
            </Grid>

            {/* Product Serial Number */}
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth required variant="outlined">
                <Controller
                  name={`products.${index}.product_serial_number`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Product Serial Number"
                      placeholder="Select Product Serial Number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Category />
                          </InputAdornment>
                        ),
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            sx: {
                              maxHeight: 250,
                            },
                          },
                        },
                      }}
                      error={!!errors.products?.[index]?.product_serial_number}
                      helperText={
                        errors.products?.[index]?.product_serial_number?.message
                      }
                    >
                      <MenuItem value="" disabled>
                        Select a Serial Number
                      </MenuItem>
                      {productSerialNumber.map((serial, idx) => (
                        <MenuItem key={idx} value={serial}>
                          {serial}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormControl>
            </Grid>

            {/* HSN Code */}
            <Grid item xs={12} sm={2}>
              <Controller
                name={`products.${index}.hsn_code`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="HSN Code"
                    type="text"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <QrCodeIcon />
                        </InputAdornment>
                      ),
                      readOnly: true,
                    }}
                    error={!!errors.products?.[index]?.hsn_code}
                    helperText={errors.products?.[index]?.hsn_code?.message}
                  />
                )}
              />
            </Grid>

            {/* Quantity */}
            <Grid item xs={12} sm={1}>
              <Controller
                name={`products.${index}.quantity`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Quantity"
                    type="number"
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FormatListNumbered />
                        </InputAdornment>
                      ),
                    }}
                    readOnly
                    error={!!errors.products?.[index]?.quantity}
                    helperText={errors.products?.[index]?.quantity?.message}
                    inputProps={{
                      min: 0, // Ensure the value can't go below 0
                    }}
                    onInput={(e) => {
                      // Prevent negative numbers
                      if (parseInt(e.target.value) < 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <Controller
                name={`products.${index}.discount`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Discount Amount"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupee />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.products?.[index]?.discount}
                    helperText={errors.products?.[index]?.discount?.message}
                    inputProps={{
                      min: 0, // Prevent values below 0
                    }}
                    onChange={(e) => {
                      const discountValue = e.target.value;
                      field.onChange(discountValue); // Update the field value in react-hook-form
                      handleDiscountChange(index, discountValue); // Trigger recalculation
                    }}
                    onInput={(e) => {
                      if (parseInt(e.target.value) < 0) {
                        e.preventDefault(); // Prevent negative inputs
                      }
                    }}
                  />
                )}
              />
            </Grid>

            {/* Sgew Price */}
            <Grid item xs={12} sm={2}>
              <Controller
                name={`products.${index}.sgew_price`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Sgew Price"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupee />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.products?.[index]?.sgew_price}
                    helperText={errors.products?.[index]?.sgew_price?.message}
                    inputProps={{
                      readOnly: true,
                      min: 0, // Ensure the value can't go below 0
                    }}
                    onInput={(e) => {
                      // Prevent negative numbers
                      if (parseInt(e.target.value) < 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Grid>

            {/* gst_amount and gst_percent */}
            <Grid item xs={12} sm={2}>
              <Controller
                name={`products.${index}.gst_amount`}
                control={control}
                render={({ field }) => {
                  const gstPercent =
                    watch(`products.${index}.gst_percent`) || 0;
                  return (
                    <TextField
                      {...field}
                      fullWidth
                      label={`GST Amount (${gstPercent}%)`}
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyRupee />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        const gstAmount = e.target.value;
                        field.onChange(gstAmount);
                        handleDiscountChange(index, gstAmount); // Perform your custom calculation
                        setValue(`products.${index}.gst_amount`, gstAmount, {
                          shouldValidate: true,
                        }); // Update and trigger re-render
                      }}
                      error={!!errors.products?.[index]?.gst_amount}
                      helperText={errors.products?.[index]?.gst_amount?.message}
                      inputProps={{
                        readOnly: true,
                        min: 0,
                      }}
                      onInput={(e) => {
                        if (parseInt(e.target.value) < 0) {
                          e.preventDefault();
                        }
                      }}
                    />
                  );
                }}
              />
            </Grid>

            {/* Amount */}
            <Grid item xs={12} sm={2}>
              <Controller
                name={`products.${index}.amount`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Amount"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupee />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.products?.[index]?.amount}
                    helperText={errors.products?.[index]?.amount?.message}
                    inputProps={{
                      readOnly: true,
                      min: 0, // Ensure the value can't go below 0
                    }}
                    onInput={(e) => {
                      // Prevent negative numbers
                      if (parseInt(e.target.value) < 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Grid>

            {/* Actions */}
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              className="flex items-center gap-3"
            >
              <IconButton onClick={() => removeProduct(index)}>
                <MdDeleteForever size={30} style={{ color: "#d0412e" }} />
              </IconButton>
              {index === productFields.length - 1 && (
                <IconButton
                  onClick={() =>
                    addProduct({
                      id: "",
                      serial_number: "",
                      product_serial_number: "",
                      hsn_code: "",
                      quantity: "1",
                      sgew_price: "",
                      gst_amount: "",
                      gst_percent: "",
                      discount: "",
                      amount: "",
                    })
                  }
                  color="primary"
                >
                  <BsPlusCircleDotted size={30} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Items Detail
          </Typography>
          <Divider />
        </Grid>

        {itemFields.map((item, index) => (
          <Grid container item spacing={2} key={item.id}>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth required variant="outlined">
                <Controller
                  name={`items.${index}.id`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Item Name"
                      placeholder="Select Item"
                      type="number"
                      onChange={(e) => {
                        field.onChange(e);
                        handleItemChange(index, e.target.value); // Auto-fill weight on selection
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            sx: {
                              maxHeight: 250,
                            },
                          },
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Category />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.items?.[index]?.id}
                      helperText={errors.items?.[index]?.id?.message}
                    >
                      <MenuItem value="" disabled>
                        Select an Item
                      </MenuItem>
                      {items.map((category) => (
                        <MenuItem key={category.id} value={category.item_id}>
                          {category.item_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={1.5}>
              <Controller
                name={`items.${index}.hsn_code`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="HSN Code"
                    type="text"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FormatListNumbered />
                        </InputAdornment>
                      ),
                      readOnly: true,
                    }}
                    error={!!errors.items?.[index]?.hsn_code}
                    helperText={errors.items?.[index]?.hsn_code?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Controller
                name={`items.${index}.sell_price`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Sell Price"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupee />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.items?.[index]?.sell_price}
                    helperText={errors.items?.[index]?.sell_price?.message}
                    inputProps={{
                      readOnly: true,
                      min: 0, // Ensure the value can't go below 0
                    }}
                    onInput={(e) => {
                      // Prevent negative numbers
                      if (parseInt(e.target.value) < 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <Controller
                name={`items.${index}.quantity`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Quantity"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Scale />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                      calculateValues(index); // Trigger dynamic calculation
                    }}
                    error={!!errors.items?.[index]?.quantity}
                    helperText={errors.items?.[index]?.quantity?.message}
                    inputProps={{
                      min: 0, // Ensure the value can't go below 0
                    }}
                    onInput={(e) => {
                      // Prevent negative numbers
                      if (parseInt(e.target.value) < 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <Controller
                name={`items.${index}.discount`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Discount"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Discount />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                      calculateValues(index); // Trigger dynamic calculation
                    }}
                    error={!!errors.items?.[index]?.discount}
                    helperText={errors.items?.[index]?.discount?.message}
                    inputProps={{
                      min: 0, // Ensure the value can't go below 0
                    }}
                    onInput={(e) => {
                      // Prevent negative numbers
                      if (parseInt(e.target.value) < 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={1.5}>
              <Controller
                name={`items.${index}.gst_amount`}
                control={control}
                render={({ field }) => {
                  // Watch gst_percent dynamically
                  const gstPercent = watch(`items.${index}.gst_percent`) || 0;

                  return (
                    <TextField
                      {...field}
                      fullWidth
                      label={`GST Amount (${gstPercent}%)`}
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Percent />
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                      error={!!errors.items?.[index]?.gst_amount}
                      helperText={errors.items?.[index]?.gst_amount?.message}
                      inputProps={{
                        min: 0, // Ensure the value can't go below 0
                      }}
                      onInput={(e) => {
                        // Prevent negative numbers
                        if (parseInt(e.target.value) < 0) {
                          e.preventDefault();
                        }
                      }}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Controller
                name={`items.${index}.amount`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Amount"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupee />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.items?.[index]?.amount}
                    helperText={errors.items?.[index]?.amount?.message}
                    inputProps={{
                      readOnly: true,
                      min: 0, // Ensure the value can't go below 0
                    }}
                    onInput={(e) => {
                      // Prevent negative numbers
                      if (parseInt(e.target.value) < 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={1}
              className="flex items-center gap-3"
            >
              <IconButton onClick={() => removeItem(index)}>
                <MdDeleteForever size={30} style={{ color: "#d0412e" }} />
              </IconButton>
              {index === itemFields.length - 1 && (
                <IconButton
                  onClick={() =>
                    addItem({
                      serial_number: "",
                      id: "",
                      hsn_code: "",
                      quantity: "",
                      sell_price: "",
                      gst_amount: "",
                      gst_percent: "",
                      discount: "",
                      amount: "",
                    })
                  }
                  color="primary"
                >
                  <BsPlusCircleDotted size={30} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            SubItems Detail
          </Typography>
          <Divider />
        </Grid>

        {subItemFields.map((subItem, index) => (
          <Grid container item spacing={2} key={subItem.id}>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth required variant="outlined">
                <Controller
                  name={`sub_items.${index}.id`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Sub Item Name"
                      onChange={(e) => {
                        field.onChange(e);
                        handleSubItemChange(index, e.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Category />
                          </InputAdornment>
                        ),
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: { sx: { maxHeight: 250 } },
                        },
                      }}
                      error={!!errors.sub_items?.[index]?.id}
                      helperText={errors.sub_items?.[index]?.id?.message}
                    >
                      <MenuItem value="" disabled>
                        Select a Sub Item
                      </MenuItem>
                      {subItems.map((subCategory) => (
                        <MenuItem key={subCategory.id} value={subCategory.id}>
                          {subCategory.subitem_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={1.5}>
              <Controller
                name={`sub_items.${index}.hsn_code`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="HSN Code"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FormatListNumbered />
                        </InputAdornment>
                      ),
                      readOnly: true,
                    }}
                    error={!!errors.sub_items?.[index]?.hsn_code}
                    helperText={errors.sub_items?.[index]?.hsn_code?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Controller
                name={`sub_items.${index}.sell_price`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Sell Price"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupee />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.sub_items?.[index]?.sell_price}
                    helperText={errors.sub_items?.[index]?.sell_price?.message}
                    inputProps={{
                      readOnly: true,
                      min: 0, // Ensure the value can't go below 0
                    }}
                    onInput={(e) => {
                      // Prevent negative numbers
                      if (parseInt(e.target.value) < 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <Controller
                name={`sub_items.${index}.quantity`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Quantity"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FormatListNumbered />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                      calculateSubItemValues(index); // Recalculate values
                    }}
                    error={!!errors.sub_items?.[index]?.quantity}
                    helperText={errors.sub_items?.[index]?.quantity?.message}
                    inputProps={{
                      min: 0, // Ensure the value can't go below 0
                    }}
                    onInput={(e) => {
                      // Prevent negative numbers
                      if (parseInt(e.target.value) < 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <Controller
                name={`sub_items.${index}.discount`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Discount"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupee />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                      calculateSubItemValues(index); // Recalculate values
                    }}
                    error={!!errors.sub_items?.[index]?.discount}
                    helperText={errors.sub_items?.[index]?.discount?.message}
                    inputProps={{
                      min: 0, // Ensure the value can't go below 0
                    }}
                    onInput={(e) => {
                      // Prevent negative numbers
                      if (parseInt(e.target.value) < 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={1.5}>
              <Controller
                name={`sub_items.${index}.gst_amount`}
                control={control}
                render={({ field }) => {
                  const gstPercent =
                    watch(`sub_items.${index}.gst_percent`) || 0; // Watch for GST percent value

                  return (
                    <TextField
                      {...field}
                      fullWidth
                      label={`GST Amount (${gstPercent}%)`} // Combine label with GST percent
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyRupee />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.sub_items?.[index]?.gst_amount}
                      helperText={
                        errors.sub_items?.[index]?.gst_amount?.message
                      }
                      inputProps={{
                        readOnly: true,
                        min: 0, // Ensure the value can't go below 0
                      }}
                      onInput={(e) => {
                        // Prevent negative numbers
                        if (parseInt(e.target.value) < 0) {
                          e.preventDefault();
                        }
                      }}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Controller
                name={`sub_items.${index}.amount`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Amount"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupee />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.sub_items?.[index]?.amount}
                    helperText={errors.sub_items?.[index]?.amount?.message}
                    inputProps={{
                      readOnly: true,
                      min: 0, // Ensure the value can't go below 0
                    }}
                    onInput={(e) => {
                      // Prevent negative numbers
                      if (parseInt(e.target.value) < 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={1}
              className="flex items-center gap-3"
            >
              <IconButton onClick={() => removeSubItem(index)}>
                <MdDeleteForever size={30} style={{ color: "#d0412e" }} />
              </IconButton>
              {index === subItemFields.length - 1 && (
                <IconButton
                  onClick={() =>
                    addSubItem({
                      id: "",
                      hsn_code: "",
                      quantity: "",
                      sell_price: "",
                      gst_amount: "",
                      gst_percent: "",
                      discount: "",
                      amount: "",
                    })
                  }
                  color="primary"
                >
                  <BsPlusCircleDotted size={30} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}

        {/* Shipping Details  */}
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Shipping Details
          </Typography>
          <Divider />
        </Grid>

        {/* customer name dropdown  */}
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth required variant="outlined">
            <Controller
              name="user_id"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Customer Name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FormatListNumbered />
                      </InputAdornment>
                    ),
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          maxHeight: 250,
                        },
                      },
                    },
                  }}
                  error={!!errors.user_id}
                  helperText={errors.user_id?.message}
                >
                  <MenuItem value="" disabled>
                    Select a Customer
                  </MenuItem>
                  {customers.map((customer) => (
                    <MenuItem key={customer.id} value={customer.id}>
                      {customer.user_name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </FormControl>
        </Grid>

        {/* Company */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="shipping_address.company"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Company"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Business />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.company}
                helperText={errors.billing_address?.company?.message}
              />
            )}
          />
        </Grid>

        {/* GST Number */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="shipping_address.gst_number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="GST Number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Description />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.gst_number}
                helperText={errors.billing_address?.gst_number?.message}
              />
            )}
          />
        </Grid>

        {/* Address Line 1 */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="shipping_address.address_line_1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Address Line 1"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.address_line_1}
                helperText={errors.billing_address?.address_line_1?.message}
              />
            )}
          />
        </Grid>

        {/* Address Line 2 */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="shipping_address.address_line_2"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Address Line 2"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.address_line_2}
                helperText={errors.billing_address?.address_line_2?.message}
              />
            )}
          />
        </Grid>

        {/* City */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="shipping_address.city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="City"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.city}
                helperText={errors.billing_address?.city?.message}
              />
            )}
          />
        </Grid>

        {/* Postal Code */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="shipping_address.post_code"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Postal Code"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <GpsFixed />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.post_code}
                helperText={errors.billing_address?.post_code?.message}
              />
            )}
          />
        </Grid>

        {/* Country */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="shipping_address.country"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Country"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Public />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.country}
                helperText={errors.billing_address?.country?.message}
              />
            )}
          />
        </Grid>

        {/* State */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="shipping_address.state"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="State"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.state}
                helperText={errors.billing_address?.state?.message}
              />
            )}
          />
        </Grid>

        {/* Email */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="shipping_address.email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.email}
                helperText={errors.billing_address?.email?.message}
              />
            )}
          />
        </Grid>

        {/* Phone */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="shipping_address.phone"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Phone"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.phone}
                helperText={errors.billing_address?.phone?.message}
              />
            )}
          />
        </Grid>

        {/* Dispatch Destination */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="shipping_address.dispatch_destination"
            control={control}
            defaultValue="2"
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Dispatch Destination"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalShipping />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.dispatch_destination}
                helperText={
                  errors.billing_address?.dispatch_destination?.message
                }
              />
            )}
          />
        </Grid>

        {/* Motor Vehicle Number */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="shipping_address.motor_vehicle_number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Motor Vehicle Number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <GpsFixed />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.billing_address?.motor_vehicle_number}
                helperText={
                  errors.billing_address?.motor_vehicle_number?.message
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Pricing Information
          </Typography>
        </Grid>

        {/* Discount Field */}
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="outlined">
            <Controller
              name="discount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Total Discount"
                  type="number"
                  placeholder="Enter discount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon /> {/* Discount icon */}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    min: 0, // Ensure the value can't go below 0
                  }}
                  onInput={(e) => {
                    // Prevent negative numbers
                    if (parseInt(e.target.value) < 0) {
                      e.preventDefault();
                    }
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        {/* Shipping Amount Field */}
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="outlined">
            <Controller
              name="shipping_amount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Shipping Amount"
                  type="number"
                  placeholder="Enter shipping cost"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocalShippingIcon /> {/* Shipping icon */}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    min: 0, // Ensure the value can't go below 0
                  }}
                  onInput={(e) => {
                    // Prevent negative numbers
                    if (parseInt(e.target.value) < 0) {
                      e.preventDefault();
                    }
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        {/* Rounded Off Amount Field */}
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="outlined">
            <Controller
              name="rounded_off_amount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rounded Off Amount"
                  type="number"
                  placeholder="Enter rounded off amount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AdjustIcon /> {/* Rounded off icon */}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    min: 0, // Ensure the value can't go below 0
                  }}
                  onInput={(e) => {
                    // Prevent negative numbers
                    if (parseInt(e.target.value) < 0) {
                      e.preventDefault();
                    }
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        {/* Total Amount Field */}
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="outlined">
            <Controller
              name="total_amount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Total Amount"
                  type="number"
                  placeholder="Enter total amount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon /> {/* Total amount icon */}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    min: 0, // Ensure the value can't go below 0
                  }}
                  onInput={(e) => {
                    // Prevent negative numbers
                    if (parseInt(e.target.value) < 0) {
                      e.preventDefault();
                    }
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        {/* Total Amount in Words Field */}
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth variant="outlined">
            <Controller
              name="total_amount_words"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Total Amount in Words"
                  placeholder="Enter total amount in words"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SpellcheckIcon /> {/* Words icon */}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        {/* Order Note Field */}
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth variant="outlined">
            <Controller
              name="order_note"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Order Note"
                  placeholder="Enter any additional notes"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <NoteIcon /> {/* Order note icon */}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        {/* Estimated Delivery Date Field */}
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="outlined">
            <Controller
              name="estimated_delivery_date"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Estimated Delivery Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarTodayIcon /> {/* Delivery date icon */}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "24px" }}>
        <FormButton type="submit" title={getButtonText()} />
      </Grid>
    </form>
  );
};

export default CreateOrder;
