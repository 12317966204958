import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../Button";
import HttpsIcon from "@mui/icons-material/Https";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { toast } from "react-toastify";
import Loader from "../../loader/Loading";
import { postReq } from "../../../apis/api";

const Reschedule = ({ open, handleClose, ItemId, onItemAdded }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      estimated_delivery_date: "", // Set default value for the field
    },
  });

  useEffect(() => {
    if (open) {
      reset(); // Reset form values when modal opens
    }
  }, [open, reset]);

  const onSubmit = async (data) => {
    const payload = {
      id: ItemId, // Include the ID
      estimated_delivery_date: data.estimated_delivery_date, // Capture the field value
    };

    console.log("Payload to be sent:", payload);

    try {
      setIsLoading(true);
      const response = await postReq(payload, `/orderReschedule`);

      if (response.status === 201) {
        toast.success("Delivery date updated successfully!", {
          position: "top-right",
          autoClose: 5000,
        });

        reset();
        handleClose();
        setTimeout(() => {
          onItemAdded();
        }, 1000);
      } else {
        toast.error(
          response.data.message || "Failed to update delivery date.",
          {
            position: "top-right",
            autoClose: 5000,
          }
        );
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.", {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-input": {
              fontSize: "1vw",
            },
            "& .MuiInputLabel-root": {
              fontSize: "1vw",
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {isLoading && <Loader />}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        sx={{
          "& .MuiDialogContent-root": {
            padding: "28px !important",
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            className="border-b border-gray-300 flex items-center justify-between"
            sx={{ position: "relative" }}
          >
            <Typography variant="h6" className="mr-auto">
              Update Delivery Date
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={4}>
              {/* Estimated Delivery Date */}
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="estimated_delivery_date"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="date"
                      label="Estimated Delivery Date"
                      placeholder="Enter delivery date"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HttpsIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              func={handleClose}
              title={"Cancel"}
              customcls="bg-[#d0412e33] text-[#D0412E] border border-[#d0412e33]"
            />
            <Button
              type="submit"
              func={handleSubmit(onSubmit)}
              title={"Update"}
            />
          </DialogActions>
        </form>
      </Dialog>
    </ThemeProvider>
  );
};

export default Reschedule;
