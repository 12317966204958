import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  TextField,
  MenuItem,
  FormControl,
  InputAdornment,
  Button,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import {
  Category,
  Code,
  FormatListNumbered,
  Weight,
  Description,
  PriceCheck,
  Percent,
  AccountCircle,
  AddCircle,
  RemoveCircle,
  Scale,
  Delete,
  CurrencyRupee,
} from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { productSchema } from "../../utils/validation";
import { getReq, nestedPostReq, postReq, rawPostReq } from "../../apis/api";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loading";
import { MdDeleteForever, MdUploadFile } from "react-icons/md";
import { BsPlusCircleDotted } from "react-icons/bs";
import FormButton from "../../components/Button";
import { useNavigate, useParams } from "react-router-dom";

const CreateProduct = () => {
  const { productId } = useParams();
  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState([]);
  const [subItems, setSubitems] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(productSchema),
    defaultValues: {
      product_category: "",
      product_name: "",
      hsn_code: "",
      product_sku: "",
      weight: "",
      product_images: [],
      display_on_website: "",
      item_details: [
        {
          id: "",
          name: "",
          quantity: "",
          weightPerUnit: "",
          sgew_price: "",
        },
      ],
      subitems: [
        {
          id: "",
          name: "",
          quantity: "",
          weightPerUnit: "",
          sgew_price: "",
        },
      ],
      amount: "",
      gst_percentage: "18",
      labour_charge: "",
      grand_total: "",
      status: "Enable",
    },
  });

  const {
    fields: itemFields,
    append: addItem,
    remove: removeItem,
  } = useFieldArray({
    control,
    name: "item_details",
  });

  const {
    fields: subItemFields,
    append: addSubItem,
    remove: removeSubItem,
  } = useFieldArray({
    control,
    name: "subitems",
  });

  useEffect(() => {
    fetchCategories();
    fetchItems();
    fetchSubitems();
    if (productId) {
      fetchProductData();
    }
  }, [productId]);

  const fetchProductData = async () => {
    try {
      setIsLoading(true);
      const res = await getReq(`/products/${productId}`);
      const productData = res.data;

      const updatedData = {
        ...productData,
        item_details: JSON.parse(productData.item_details || "[]"), // Parse if it's a string, or fallback to an empty array
        subitems: JSON.parse(productData.subitems || "[]"), // Parse if it's a string, or fallback to an empty array
        product_images: JSON.parse(productData.product_images || "[]"),
      };

      const initialPreviews = updatedData.product_images.map( 
        // https://sgewbackend.reflomsolutions.com/uploads/1731655837_07810587f5074b153a95.jpg
        (img) => `https://sgewbackend.reflomsolutions.com/uploads/${img}`
      );
      setImagePreviews(initialPreviews);

      // Reset the form with the updated data
      reset(updatedData);
    } catch (error) {
      console.error("Error fetching product:", error);
      toast.error("Failed to load product data");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const res = await getReq("/product-categories");
      setCategories(res.data || []);
    } catch (e) {
      console.error("Error fetching categories:", e);
      toast.error("Error in fetching categories");
    }
  };
  const fetchItems = async () => {
    try {
      const res = await getReq("/fetchItems");
      setItems(res.data || []);
    } catch (e) {
      console.error("Error fetching Items:", e);
      toast.error("Error in fetching Items");
    }
  };

  const fetchSubitems = async () => {
    try {
      const res = await getReq("/subitems");
      setSubitems(res.data || []);
    } catch (e) {
      console.error("Error fetching Items:", e);
      toast.error("Error in fetching Items");
    }
  };

  // const handleImageUpload = (event) => {
  //   const files = Array.from(event.target.files);
  //   const existingImages = getValues("product_images") || []; // Ensure existingImages is an array

  //   // Filter out any non-File entries from existingImages and combine with new File objects
  //   const allImages = [
  //     ...existingImages.filter((img) => img instanceof File),
  //     ...files,
  //   ];
  //   setValue("product_images", allImages);

  //   // Generate previews for the new images only
  //   const newPreviews = files.map((file) => URL.createObjectURL(file));
  //   setImagePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
  // };

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const existingImages = getValues("product_images") || [];

    // Combine existing images and new uploads
    const allImages = [...existingImages, ...files];
    setValue("product_images", allImages);

    // Generate previews for the new images only
    const newPreviews = files.map((file) => URL.createObjectURL(file));
    setImagePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
  };

  const handleRemoveImage = (index) => {
    const updatedImages = getValues("product_images").filter(
      (_, i) => i !== index
    );
    setValue("product_images", updatedImages);

    const updatedPreviews = imagePreviews.filter((_, i) => i !== index);
    setImagePreviews(updatedPreviews);
  };
  const watchedItemDetails = watch("item_details");
  const watchedSubItems = watch("subitems");
  const Amount = watch("amount");
  const labourCharge = watch("labour_charge");
  const gstPercentage = watch("gst_percentage");

  useEffect(() => {
    // Parse values as floats to ensure numeric calculations
    const amount = parseFloat(totalAmount) || 0;
    const labour = parseFloat(labourCharge) || 0;
    const gst = parseFloat(gstPercentage) || 0;

    // Calculate GST amount and grand total
    const gstAmount = (amount * gst) / 100;
    const grandTotal = amount + labour + gstAmount;

    // Update grand total, rounding to two decimal places
    setValue("grand_total", grandTotal.toFixed(2));
    setValue("amount", totalAmount.toFixed(2));
  }, [totalAmount, labourCharge, gstPercentage, setValue]);

  const handleItemChange = (index, selectedItem) => {
    const item = items.find((i) => i.item_id === selectedItem);
    if (item) {
      // Convert values to numbers explicitly
      setValue(`item_details.${index}.id`, Number(item.item_id));
      setValue(`item_details.${index}.name`, item.item_name);
      setValue(
        `item_details.${index}.weightPerUnit`,
        parseFloat(item.default_weight_kg)
      );
      setValue(`item_details.${index}.sgew_price`, parseFloat(item.sgew_price));
    }
  };

  const handleSubItemChange = (index, selectedSubItem) => {
    const subItem = subItems.find((i) => i.id === selectedSubItem);
    if (subItem) {
      setValue(`subitems.${index}.id`, subItem.id);
      setValue(`subitems.${index}.name`, subItem.subitem_name);
      setValue(`subitems.${index}.weightPerUnit`, subItem.default_weight_kg);
      setValue(`subitems.${index}.sgew_price`, subItem.sgew_price);
    }
  };

  const calculateTotalAmount = () => {
    let total = 0;

    // Loop through each item to get quantity and price and calculate
    watchedItemDetails.forEach((item) => {
      const quantity = parseFloat(item.quantity) || 0;
      const sgewPrice = parseFloat(item.sgew_price) || 0;
      total += quantity * sgewPrice;
    });

    // Loop through each subitem for the same calculation
    watchedSubItems.forEach((subItem) => {
      const quantity = parseFloat(subItem.quantity) || 0;
      const sgewPrice = parseFloat(subItem.sgew_price) || 0;
      total += quantity * sgewPrice;
    });

    setTotalAmount(total);
  };

  // Recalculate total whenever itemFields or subItemFields change
  useEffect(() => {
    calculateTotalAmount();
  }, [watchedItemDetails, watchedSubItems, setTotalAmount]);

  const onSubmit = async (data) => {
    // console.log("data", JSON.stringify(data));
    setIsLoading(true);
    try {
      // Determine API endpoint and method based on the mode
      const apiUrl = productId ? `/products/${productId}` : "/products";

      const response = productId
        ? await rawPostReq(data, apiUrl)
        : await rawPostReq(data, apiUrl);

      // Handle successful response
      if (response.status === 200 || response.status === 201) {
        toast.success(
          productId
            ? "Product updated successfully!"
            : "Product created successfully!"
        );
        reset(); 
        navigate(-1)
      } else {
        // Display error for non-success status codes
        toast.error(response.data.message || "Operation failed.", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle specific error messages if available
      if (
        error.response &&
        error.response.data &&
        error.response.data.messages
      ) {
        const errorMessages = error.response.data.messages;
        // Display each error message in a toast
        Object.values(errorMessages).forEach((msg) => {
          toast.error(msg, {
            position: "top-right",
            autoClose: 5000,
          });
        });
      } else {
        // Fallback error message
        toast.error("Something went wrong. Please try again.", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form 
      className="bg-white"
      onSubmit={handleSubmit(onSubmit)}
      style={{
        width: "100%",
        margin: "0 auto",
        padding: "20px",
        background: "#fffff",
        borderRadius: "8px",
      }}
    >
      {isLoading && <Loader />}
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "28px", 
            padding:"20px"
          }}
        >
          {/* Back Icon Button */}
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => navigate(-1)} // Go back to the previous page
            aria-label="back"
            sx={{ position: "absolute", left: "20px", ml: "20px" }} // Add margin-left here
          >
            <ArrowBackIcon />
          </IconButton>

          {/* Product Information Text */}
          <Typography variant="h5" gutterBottom sx={{ flexGrow: 1 }}>
            Product Information
          </Typography>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth required variant="outlined">
            <Controller
              name="product_category"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Product Category"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FormatListNumbered />
                      </InputAdornment>
                    ),
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          maxHeight: 250,
                        },
                      },
                    },
                  }}
                  error={!!errors.product_category}
                  helperText={errors.product_category?.message}
                >
                  <MenuItem value="" disabled>
                    Select a category
                  </MenuItem>
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.name}>
                      {category.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="product_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Product Name"
                placeholder="Enter product name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Category />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.product_name}
                helperText={errors.product_name?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="hsn_code"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="HSN Code"
                placeholder="Enter HSN code"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Code />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.hsn_code}
                helperText={errors.hsn_code?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="product_sku"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Product SKU"
                placeholder="Enter Product SKU"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Code />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.product_sku}
                helperText={errors.product_sku?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Controller
            name="product_description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Product Description"
                placeholder="Enter detailed product description"
                multiline
                rows={2} // Adjust number of rows as needed for more or less space
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Description />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.product_description}
                helperText={errors.product_description?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="short_description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Short Description"
                placeholder="Enter brief description"
                multiline
                rows={2} // Fewer rows for a shorter description
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Description />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.short_description}
                helperText={errors.short_description?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="weight"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Weight"
                placeholder="Enter weight"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Scale />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.weight}
                helperText={errors.weight?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            component="label"
            color="primary"
            sx={{
              display: "inline-flex",
              alignItems: "center",
              gap: 1,
              backgroundColor: "#0074ff",
              color: "#ffffff",
              fontWeight: "500",
            }}
          >
            <MdUploadFile size={20} style={{ marginRight: "6px" }} />
            Upload Images
            <input type="file" hidden multiple onChange={handleImageUpload} />
          </Button>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            {imagePreviews.map((src, index) => (
              <div
                key={index}
                style={{ position: "relative", display: "inline-block" }}
              >
                <img
                  src={src}
                  alt={`preview-${index}`}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
                <IconButton
                  onClick={() => handleRemoveImage(index)}
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                  }}
                  size="small"
                >
                  <Delete fontSize="small" />
                </IconButton>
              </div>
            ))}
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="outlined">
            <Controller
              name="display_on_website"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Display on Website "
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Scale />
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.display_on_website}
                  helperText={errors.display_on_website?.message}
                >
                  <MenuItem value="Enable">Yes</MenuItem>
                  <MenuItem value="Disable">No</MenuItem>
                </TextField>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="service_duration"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Service Duration"
                placeholder="Enter Service Duration"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccessTimeIcon />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.service_duration}
                helperText={errors.service_duration?.message}
              />
            )}
          />
        </Grid>
       
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth variant="outlined">
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label="Status"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Scale />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.status}
                    helperText={errors.status?.message}
                  >
                    <MenuItem value="Enable">Enable</MenuItem>
                    <MenuItem value="Disable">Disable</MenuItem>
                  </TextField>
                )}
              />
            </FormControl>
          </Grid>


        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Item Details
          </Typography>
          <Divider />
        </Grid>

        {itemFields.map((item, index) => (
          <Grid container item spacing={2} key={item.id}>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth required variant="outlined">
                <Controller
                  name={`item_details.${index}.id`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Item Name"
                      placeholder="Select Item"
                      type="number"
                      onChange={(e) => {
                        field.onChange(e);
                        handleItemChange(index, e.target.value); // Auto-fill weight on selection
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Category />
                          </InputAdornment>
                        ),
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            sx: {
                              maxHeight: 250,
                            },
                          },
                        },
                      }}
                      error={!!errors.item_details?.[index]?.name}
                      helperText={errors.item_details?.[index]?.name?.message}
                    >
                      <MenuItem value="" disabled>
                        Select an Item
                      </MenuItem>
                      {items.map((category) => (
                        <MenuItem key={category.id} value={category.item_id}>
                          {category.item_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Controller
                name={`item_details.${index}.quantity`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Quantity"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FormatListNumbered />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.item_details?.[index]?.quantity}
                    helperText={errors.item_details?.[index]?.quantity?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Controller
                name={`item_details.${index}.weightPerUnit`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Weight/Unit"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Scale />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.item_details?.[index]?.weightPerUnit}
                    helperText={
                      errors.item_details?.[index]?.weightPerUnit?.message
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Controller
                name={`item_details.${index}.sgew_price`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Sgew Price"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupee />
                        </InputAdornment>
                      ),
                    }}
                    readOnly="true"
                    error={!!errors.item_details?.[index]?.sgew_price}
                    helperText={
                      errors.item_details?.[index]?.sgew_price?.message
                    }
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              className="flex items-center gap-3"
            >
              <IconButton onClick={() => removeItem(index)}>
                <MdDeleteForever size={30} style={{ color: "#d0412e" }} />
              </IconButton>
              {index === itemFields.length - 1 && (
                <IconButton
                  onClick={() =>
                    addItem({
                      id: "",
                      name: "",
                      quantity: "",
                      weightPerUnit: "",
                      sgew_price: "",
                    })
                  }
                  color="primary"
                >
                  <BsPlusCircleDotted size={30} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            SubItems Details
          </Typography>
          <Divider />
        </Grid>

        {subItemFields.map((subItem, index) => (
          <Grid container item spacing={2} key={subItem.id}>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth required variant="outlined">
                <Controller
                  name={`subitems.${index}.id`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Sub Item Name"
                      placeholder="Select Sub Item"
                      onChange={(e) => {
                        field.onChange(e);
                        handleSubItemChange(index, e.target.value); // Auto-fill weight for sub-item
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Category />
                          </InputAdornment>
                        ),
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            sx: {
                              maxHeight: 250,
                            },
                          },
                        },
                      }}
                      error={!!errors.subitems?.[index]?.name}
                      helperText={errors.subitems?.[index]?.name?.message}
                    >
                      <MenuItem value="" disabled>
                        Select a Sub Item
                      </MenuItem>
                      {subItems.map((subCategory) => (
                        <MenuItem key={subCategory.id} value={subCategory.id}>
                          {subCategory.subitem_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Controller
                name={`subitems.${index}.quantity`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Quantity"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FormatListNumbered />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.subitems?.[index]?.quantity}
                    helperText={errors.subitems?.[index]?.quantity?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Controller
                name={`subitems.${index}.weightPerUnit`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Weight/Unit"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Scale />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.subitems?.[index]?.weightPerUnit}
                    helperText={
                      errors.subitems?.[index]?.weightPerUnit?.message
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Controller
                name={`subitems.${index}.sgew_price`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Sgew Price"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupee />
                        </InputAdornment>
                      ),
                    }}
                    readOnly="true"
                    error={!!errors.subitems?.[index]?.sgew_price}
                    helperText={errors.subitems?.[index]?.sgew_price?.message}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              className="flex items-center gap-3"
            >
              <IconButton onClick={() => removeSubItem(index)}>
                <MdDeleteForever size={30} style={{ color: "#d0412e" }} />
              </IconButton>
              {index === subItemFields.length - 1 && (
                <IconButton
                  onClick={() =>
                    addSubItem({
                      id: "",
                      name: "",
                      quantity: "",
                      weightPerUnit: "",
                      sgew_price: "",
                    })
                  }
                  color="primary"
                >
                  <BsPlusCircleDotted size={30} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Pricing Information
          </Typography>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Amount"
                type="number"
                placeholder="Enter amount"
                value={totalAmount} // Bind total amount to the input value
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PriceCheck />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.amount}
                helperText={errors.amount?.message}
                readOnly
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="labour_charge"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="labour Charge"
                type="number"
                placeholder="Enter labour Charge"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PriceCheck />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.labour_charge}
                helperText={errors.labour_charge?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="gst_percentage"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="GST Percentage"
                type="number"
                placeholder="Enter GST percentage"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Percent />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.gst_percentage}
                helperText={errors.gst_percentage?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="grand_total"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Grand Total"
                type="number"
                placeholder="Enter Grand Total"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.grand_total}
                helperText={errors.grand_total?.message}
                readOnly
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ marginTop: "24px" }}>
        <FormButton
          type="submit"
          title={productId ? "Update Product" : "Create Product"}
        />
      </Grid>
    </form>
  );
};

export default CreateProduct;
