import * as Yup from "yup";  

// Basic date format
const dateFormat = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;

export const validationSchema = Yup.object().shape({
  user_name: Yup.string().required("User Name is required"),
  father_name: Yup.string().required("Father's Name is required"),
  phone: Yup.string()
    .required("Phone Number is required")
    .matches(/^[0-9]+$/, "Phone Number must be digits only")
    .min(10, "Phone Number must be at least 10 digits")
    .max(10, "Phone Number must be at most 10 digits"),

  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  address_line_1: Yup.string().required("Address Line 1 is required"),
  village: Yup.string().required("Village is required"),
  district: Yup.string().required("District is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
  // gstin_number: Yup.string()
  //   .matches(
  //     /^([0-9]{2})([A-Z]{5})([0-9]{4})([A-Z]{1})([A-Z0-9]{3})$/,
  //     "Invalid GSTIN format"
  //   ),
  // dealer_price_discount: Yup.number()
  //   .typeError("Dealer Price Discount must be a number")
  //   .min(0, "Discount must be greater than or equal to 0"),
  // employee_monthly_salary: Yup.number()
  //   .typeError("Monthly Salary must be a number")
  //   .min(0, "Salary must be greater than or equal to 0")
  //   .required("Monthly Salary is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters long"),
  // role_id: Yup.string().required("Role ID is required"),
  start_login_time: Yup.string()
    .required("Start login time is required")
    .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, "Invalid time format (HH:mm)"),
  end_login_time: Yup.string()
    .required("End login time is required")
    .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, "Invalid time format (HH:mm)"),
  payment_sms_reminder: Yup.string().nullable(),
  // employee_type: Yup.string().required("Employee Type is required"),
  // overtime_per_hour_salary: Yup.number()
  //   .typeError("Overtime Salary must be a number")
  //   .min(0, "Overtime Salary must be greater than or equal to 0")
  //   .required("Overtime Salary is required"),
}); 

export const itemSchema = Yup.object().shape({
  item_category_id: Yup.string().required('Item category is required'), // Required
  item_name: Yup.string()
    .required('Item name is required')
    .min(2, 'Item name must be at least 2 characters'), // Required
  hsn: Yup.string().required('HSN is required'), // Required
  default_length_mm: Yup.number()
    .nullable() // Optional
    .positive('Must be a positive number'), // Positive if provided
  default_width_mm: Yup.number()
    .nullable() // Optional
    .positive('Must be a positive number'), // Positive if provided
  default_thickness_mm: Yup.number()
    .nullable() // Optional
    .positive('Must be a positive number'), // Positive if provided
  default_weight_kg: Yup.number()
    .nullable() // Optional
    .positive('Must be a positive number'), // Positive if provided
  default_tax_percentage: Yup.number()
    .nullable() // Optional
    .min(0, 'Must be at least 0')
    .max(100, 'Must be at most 100'), // Positive if provided
  usage_type: Yup.string().required('Usage type is required'), 
  type: Yup.string().required('Item type is required'), // Required
});
  
export const editItemSchema = Yup.object().shape({
  item_category_id: Yup.string().required('Item category is required'), // Required
  item_name: Yup.string()
    .required('Item name is required')
    .min(2, 'Item name must be at least 2 characters'), // Required
  hsn: Yup.string().required('HSN is required'), // Required
  default_length_mm: Yup.number()
    .nullable() // Optional
    .positive('Must be a positive number'), // Positive if provided
  default_width_mm: Yup.number()
    .nullable() // Optional
    .positive('Must be a positive number'), // Positive if provided
  default_thickness_mm: Yup.number()
    .nullable() // Optional
    .positive('Must be a positive number'), // Positive if provided
  default_weight_kg: Yup.number()
    .nullable() // Optional
    .positive('Must be a positive number'), // Positive if provided
  default_tax_percentage: Yup.number()
    .nullable() // Optional
    .min(0, 'Must be at least 0')
    .max(100, 'Must be at most 100'), // Positive if provided
  usage_type: Yup.string().required('Usage type is required'), 
  type: Yup.string().required('Item type is required'), // Required
  stock: Yup.number()
    .nullable() // Optional
    .min(0, 'Stock cannot be negative'), // Optional
  unit_price: Yup.number()
    .nullable() // Optional
    .min(0, 'Unit price cannot be negative'), // Optional
  sell_price: Yup.number()
    .nullable() // Optional
    .min(0, 'Sell price cannot be negative'), // Optional
  expected_delivery_party: Yup.string()
    .nullable() // Optional
    .min(2, 'Expected delivery party must be at least 2 characters'), // Optional
  minimum_stock_alert: Yup.number()
    .nullable() // Optional
    .min(0, 'Minimum stock alert cannot be negative'), // Optional
}); 

export const splitFormSchema = Yup.object().shape({
  item_id: Yup.string().required("Item ID is required"),
  item_name: Yup.string().required("Item name is required"),
  item_weight: Yup
    .number()
    .min(1, "Total available weight must be at least 1")
    .required("Total available weight is required"),
  sgew_price: Yup
    .number()
    .min(0, "Price cannot be negative")
    .required("Item SGEW price is required"),
  subitems: Yup.array().of(
    Yup.object().shape({
      subitem_id: Yup.string().required("Subitem ID is required"),
      length: Yup.number().required("Length is required"),
      width: Yup.number().required("Width is required"),
      thickness: Yup.number().required("Thickness is required"),
      weight: Yup.number().required("Weight is required"),
      quantity: Yup.number().required("Quantity is required"),
      sgew_price: Yup
        .number()
        .min(0, "Price cannot be negative")
        .required("SGEW price is required"),
      created_by: Yup.string().required("Creator is required"),
    })
  ),
}); 

// productSchema.js
export const productSchema = Yup.object().shape({
  product_category: Yup.string().required('Product category is required'),
  product_name: Yup.string().required('Product name is required'),
  hsn_code: Yup.string().required('HSN code is required'),
  product_sku: Yup.string().required('Product SKU is required'),
  weight: Yup.number().typeError('Weight must be a number').required('Weight is required'),
  // product_images: Yup.array().of(Yup.string().url('Each image must be a valid URL')),
  display_on_website: Yup.string().oneOf(['Enable', 'Disable'], 'Display option must be Enable or Disable'),
  item_details: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().typeError('ID must be a number').required('ID is required'),
      name: Yup.string().required('Item name is required'),
      quantity: Yup.number().typeError('Quantity must be a number').required('Quantity is required'),
      weightPerUnit: Yup.number().typeError('Weight per unit must be a number').required('Weight per unit is required'),
      sgew_price: Yup.number().typeError('Sgew price must be a number').required('Sgew price is required'),
    })
  ),
  subitems: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().typeError('ID must be a number').required('ID is required'),
      name: Yup.string().required('Subitem name is required'),
      quantity: Yup.number().typeError('Quantity must be a number').required('Quantity is required'),
      weightPerUnit: Yup.number().typeError('Weight per unit must be a number').required('Weight per unit is required'),
      sgew_price: Yup.number().typeError('Sgew price must be a number').required('Sgew price is required'),
    })
  ),
  amount: Yup.number().typeError('Amount must be a number').required('Amount is required'),
  gst_percentage: Yup.number().typeError('GST percentage must be a number').required('GST percentage is required'),
  service_duration: Yup.number().typeError('Service Duration must be number').required('GST percentage is required'),
  labour_charge: Yup.number().typeError('Labour charge must be a number').required('Labour charge is required'),
  grand_total: Yup.number().typeError('Grand total must be a number').required('Grand total is required'),
});
 


// Define the order schema with validation rules
export const orderSchema = Yup.object({
  invoice_number: Yup.string().required("The invoice_number field is required."),
  order_date: Yup.string().matches(dateFormat, "Invalid date format").required("The order_date field is required."),
  tally_invoice_number: Yup.string().nullable(),
  eway_bill_number: Yup.string().nullable(),
  user_id: Yup.string().required("The user_id field is required."),
  
  billing_address: Yup.object({
    name: Yup.string().required("The billing_address.name field is required."),
    company: Yup.string().required("The billing_address.company field is required."),
    gst_number: Yup.string().required("The billing_address.gst_number field is required."),
    address_line_1: Yup.string().required("The billing_address.address_line_1 field is required."),
    address_line_2: Yup.string().nullable(),
    city: Yup.string().required("The billing_address.city field is required."),
    post_code: Yup.string().required("The billing_address.post_code field is required."),
    country: Yup.string().required("The billing_address.country field is required."),
    state: Yup.string().required("The billing_address.state field is required."),
    email: Yup.string().email("Invalid email format").required("The billing_address.email field is required."),
    phone: Yup.string().required("The billing_address.phone field is required."),
    dispatch_destination: Yup.string().nullable(),
    motor_vehicle_number: Yup.string().nullable(),
  }),
  
  shipping_address: Yup.object({
    name: Yup.string().required("The shipping_address.name field is required."),
    company: Yup.string().required("The shipping_address.company field is required."),
    gst_number: Yup.string().required("The shipping_address.gst_number field is required."),
    address_line_1: Yup.string().required("The shipping_address.address_line_1 field is required."),
    address_line_2: Yup.string().nullable(),
    city: Yup.string().required("The shipping_address.city field is required."),
    post_code: Yup.string().required("The shipping_address.post_code field is required."),
    country: Yup.string().required("The shipping_address.country field is required."),
    state: Yup.string().required("The shipping_address.state field is required."),
    email: Yup.string().email("Invalid email format").required("The shipping_address.email field is required."),
    phone: Yup.string().required("The shipping_address.phone field is required."),
    dispatch_destination: Yup.string().nullable(),
    motor_vehicle_number: Yup.string().nullable(),
  }),

  products: Yup.array().of(
    Yup.object({
      serial_number: Yup.string().required("The products.*.serial_number field is required."),
      id: Yup.string().required("The products.*.id field is required."),
      hsn_code: Yup.string().required("The products.*.hsn_code field is required."),
      sgew_price: Yup.string().required("The products.*.sgew_price field is required."),
      gst_amount: Yup.string().required("The products.*.gst_amount field is required."),
      gst_percent: Yup.string().required("The products.*.gst_percent field is required."),
      amount: Yup.string().required("The products.*.amount field is required."),
    })
  ),

  items: Yup.array().of(
    Yup.object({
      id: Yup.string().required("The items.*.id field is required."),
      hsn_code: Yup.string().required("The items.*.hsn_code field is required."),
      quantity: Yup.string().required("The items.*.quantity field is required."),
      sell_price: Yup.string().required("The items.*.sell_price field is required."),
      gst_amount: Yup.string().required("The items.*.gst_amount field is required."),
      gst_percent: Yup.string().required("The items.*.gst_percent field is required."),
      amount: Yup.string().required("The items.*.amount field is required."),
    })
  ),
  sub_items: Yup.array().of(
    Yup.object({
      id: Yup.string().required("The sub_items.*.id field is required."),
      hsn_code: Yup.string().required("The sub_items.*.hsn_code field is required."),
      quantity: Yup.string().required("The sub_items.*.quantity field is required."),
      sell_price: Yup.string().required("The sub_items.*.sell_price field is required."),
      gst_amount: Yup.string().required("The sub_items.*.gst_amount field is required."),
      gst_percent: Yup.string().required("The sub_items.*.gst_percent field is required."),
      amount: Yup.string().required("The sub_items.*.amount field is required."),
    })
  ),

  discount: Yup.string().nullable(),
  shipping_amount: Yup.string().required("The shipping_amount field is required."),
  rounded_off_amount: Yup.string().nullable(),
  total_amount: Yup.string().required("The total_amount field is required."),
  total_amount_words: Yup.string().required("The total_amount_words field is required."),
  order_note: Yup.string().nullable(),
  estimated_delivery_date: Yup.string().nullable(),
});


