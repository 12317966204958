import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_API_URL;

export const checkUser = (data) => {
  const formData = new FormData();

  formData.append("phone", data);
  return axios.post(`${BASE_URL}/checkuser`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const postReq = (data, endPoint) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  return axios.post(`${BASE_URL}${endPoint}`, formData, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "multipart/form-data",
    },
  });
}; 


export const jsonpostReq = (data, endPoint) => {
  return axios.post(`${BASE_URL}${endPoint}`, data, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "application/json",
    },
  });
};




export const nestedPostReq = (data, endPoint) => {
  const formData = new FormData();

  // Function to append items to FormData, supporting nested structures
  const appendFormData = (formData, data, parentKey = "") => {
    if (data && typeof data === "object" && !Array.isArray(data)) {
      Object.keys(data).forEach((key) => {
        appendFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else if (Array.isArray(data)) {
      data.forEach((item, index) => {
        appendFormData(formData, item, `${parentKey}[${index}]`);
      });
    } else {
      formData.append(parentKey, data);
    }
  };

  // Append all fields from the main data object
  appendFormData(formData, data);

  return axios.post(`${BASE_URL}${endPoint}`, formData, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const rawPostReq = (data, endPoint) => {
  const formData = new FormData();

  // Function to append nested array items as separate fields
  const appendNestedArray = (array, key) => {
    array.forEach((item, index) => {
      Object.keys(item).forEach((subKey) => {
        formData.append(`${key}[${index}][${subKey}]`, item[subKey]);
      });
    });
  };

  Object.keys(data).forEach((key) => {
    if (key === "product_images" && Array.isArray(data.product_images)) {
      // Append each image file in `product_images`
      data.product_images.forEach((file, index) => {
        if (file instanceof File) {
          formData.append(`product_images[${index}]`, file);
        }
      });
    } else if (key === "item_details") {
      // Append `item_details` array in the required format
      appendNestedArray(data.item_details, "item_details");
    } else if (key === "subitems") {
      // Append `subitems` array in the required format
      appendNestedArray(data.subitems, "subitems");
    } else {
      // Append other fields directly
      formData.append(key, data[key]);
    }
  });

  return axios.post(`${BASE_URL}${endPoint}`, formData, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};


export const postReqSecure = (data, endPoint) => {
  const urlSearchParams = new URLSearchParams();
  Object.keys(data).forEach((key) => {
    urlSearchParams.append(key, data[key]);
  });

  return axios.post(`${BASE_URL}${endPoint}`, urlSearchParams, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

export const putReq = (data, endPoint) => {
  const urlSearchParams = new URLSearchParams();
  Object.keys(data).forEach((key) => {
    urlSearchParams.append(key, data[key]);
  });

  return axios.put(`${BASE_URL}${endPoint}`, urlSearchParams, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

export const postReqLogin = (data, endPoint) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  return axios.post(`${BASE_URL}${endPoint}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const logoutReq = async (token) => {
  try {
    if (!token) {
      return {
        status: 401,
        message: "No token provided.",
      };
    }

    const response = await axios.post(
      `${BASE_URL}/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Check if the response status is 200 but also inspect the response message
    const message = response.data.msg?.trim() || "Logout successful.";
    const status = message === "Invalid auth" ? 403 : response.status; // Adjust status based on message

    return {
      status,
      message,
    };
  } catch (error) {
    console.error("Logout error:", error);

    if (error.response) {
      return {
        status: error.response.status,
        message:
          error.response.data.msg?.trim() || "Server error during logout.",
      };
    } else if (error.request) {
      return {
        status: 500,
        message: "No response received from the server.",
      };
    } else {
      return {
        status: 500,
        message: "An unexpected error occurred: " + error.message,
      };
    }
  }
};

export const getReq = async (endpoint) => {
  try {
    const response = await axios.get(`${BASE_URL}/${endpoint}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
